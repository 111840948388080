<template>
  <el-dialog
      title="快递详情"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
    <el-timeline :reverse="false" style="margin-top: 10px">
      <el-timeline-item
          v-for="(activity, index) in expressList"
          :key="index"
          :timestamp="activity.trackMsgTime">
        {{activity.trackContent}}
      </el-timeline-item>
    </el-timeline>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {getExpressCodeListApi, getExpressInfoApi} from "@/api/orderApi";

export default {
  data() {
    return {
      dialogVisible:false,
      expressList:[],
      reverse: true,
    }
  },
  name: "express",
  methods: {
    init(item,type){
      let {expressNo,otherExpressNo,orderNo}=item
      if (type===1){
        if (expressNo){
          this.dialogVisible=true;
          this.getData(orderNo,expressNo,type)
        }
      }else{
        if (otherExpressNo){
          this.dialogVisible=true;
          this.getData(orderNo,otherExpressNo,type)
        }
      }
    },
    async getData(orderNo,otherExpressNo,type){
      let {data}=await getExpressInfoApi({
        orderNo,
        type
      })
      this.expressList=data
    },
    handleClose(){
      this.dialogVisible=false

    }
  }
}
</script>
