import service from "@/utils/request"



export function getAfsListApi(params){
   return service({
       url:"/backAfterSale/getOrderAfsPage",
       method:"get",
       params
   })
}

export function getOrderAfsDetailApi(params){
    return service({
        url:"/backAfterSale/getOrderAfsDetail",
        method:"get",
        params
    })
}

export  function submitAfsUpdateStatusApi(data){
    return service({
        url:"/backAfterSale/submitAfsUpdateStatus",
        method:"post",
        data
    })
}
