<template>
  <div>
    <VipPayComponent ref="VipPayComponent" @checkPay="checkPay"></VipPayComponent>
    <TitleCard
        :title="isNaN($route.params.id) ? `新增商品` : `编辑商品`"
        style="margin: 10px 10px; max-width: 99%; border-radius: 15px"
    >
      <template v-slot:subTitle>
        <ul
            class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1"
            style="display: flex"
        >
          <li
              class="breadcrumb-item text-muted"
              rel="stylesheet"
              href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"
              style="font-family: 'Roboto', sans-serif"
          >
            Add Products
          </li>

          <div
              class="align-items-center py-3"
              style="position: absolute; right: 0; padding: 0px 30px"
          >
            <a
                href="javascript:void(0)"
                class="btn btn-sm btn-primary mx-2"
                @click="$router.replace('/goods')"
            >返回到自营商品管理</a
            >
          </div>
        </ul>
      </template>
    </TitleCard>
    <div class="app-main flex-column flex-row-fluid" id="kt_app_main">
      <div class="d-flex flex-column flex-column-fluid">
        <div id="kt_app_content" class="app-content" style="padding-top: 10px">
          <form
              onsubmit="return false;"
              id="kt_ecommerce_add_product_form"
              class="form d-flex flex-column flex-lg-row"
              data-kt-redirect="../../demo43/dist/apps/ecommerce/catalog/products.html"
          >
            <div
                class="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10"
                style="padding-left: 13px; margin-right: 14px !important"
            >
              <div class="card card-flush py-4">
                <div class="card-header">
                  <div class="card-title">
                    <h2 class="required">主图</h2>
                  </div>
                </div>
                <div class="card-body text-center pt-0">
                  <div
                      class="image-input image-input-outline image-input-placeholder mb-3"
                      :class="
                      goodsInfo.picUrl != ''
                        ? `image-input-empty`
                        : `image-input-changed`
                    "
                      data-kt-image-input="true"
                  >
                    <!-- image-input image-input-empty image-input-outline image-input-placeholder mb-3 -->
                    <div
                        class="image-input-wrapper w-150px h-150px"
                        :style="`background-image: url(${goodsInfo.picUrl})`"
                    ></div>
                    <label
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        title="上传主图"
                    >
                      <i class="ki-outline ki-pencil fs-7"></i>
                      <input
                          type="file"
                          name="avatar"
                          accept=".png, .jpg, .jpeg"
                          @change="uploadFileAvatar"
                      />
                      <input type="hidden" name="avatar_remove"/>
                    </label>
                    <span
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="cancel"
                        data-bs-toggle="tooltip"
                        @click="romverAvatar"
                        title="删除主图"
                    >
                      <i class="ki-outline ki-cross fs-2"></i>
                    </span>
                    <span
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="remove"
                        data-bs-toggle="tooltip"
                        title="Remove avatar"
                    >
                      <i class="ki-outline ki-cross fs-2"></i>
                    </span>
                  </div>
                  <div class="text-muted fs-7">
                    设置产品缩略图。仅上传 *.png、*.jpg 和 *.jpeg 图片文件。
                  </div>
                </div>
              </div>
              <div class="card card-flush py-4">
                <div class="card-header">
                  <div class="card-title">
                    <h4 class="required">是否上架</h4>
                  </div>
                </div>
                <div v-if="(role.includes('firstGradeSupplier') || role.includes('specialFirstGrade')) || role.includes('vipMember')" class="card-body pt-0">
                  <el-switch
                      v-model="goodsInfo.isShow"
                      active-color="#13ce66"
                      inactive-color="#999"
                      active-value="1"
                      inactive-value="2"
                  >
                  </el-switch>
                </div>
                <div class="card-body pt-0" v-if="role.includes('secondTierSuppliers')">
                  <el-switch
                      v-model="goodsInfo.isTwoShow"
                      active-color="#13ce66"
                      inactive-color="#999"
                      :active-value="1"
                      :inactive-value="2"
                  >
                  </el-switch>
                </div>
                <div v-if="(role.includes('firstGradeSupplier') || role.includes('specialFirstGrade')) || role.includes('vipMember')" class="card-header">
                  <div class="card-title">
                    <h4 v-if="(role.includes('firstGradeSupplier') || role.includes('specialFirstGrade'))" class="required">是否加入全国商品池</h4>
                    <h4 v-if="role.includes('vipMember')" class="required">是否加入商会商品池</h4>
                  </div>
                </div>
                <div v-if="(role.includes('firstGradeSupplier') || role.includes('specialFirstGrade')) || role.includes('vipMember')" class="card-body pt-0">
                  <el-switch
                      v-model="goodsInfo.isQuan"
                      active-color="#13ce66"
                      inactive-color="#999"
                      active-value="1"
                      inactive-value="2"
                  >
                  </el-switch>
                </div>
              </div>
              <div class="card card-flush py-4">
                <div class="card-header">
                  <div class="card-title">
                    <h2>价格管理</h2>
                    <label
                        v-if="((role.includes('firstGradeSupplier') || role.includes('specialFirstGrade'))|| role.includes('vipMember'))&& goodsInfo.type == 2"
                        class="ms-2 mt-5 form-label fw-bold" style="font-size: 12px">
                      供应商分销价为：<label class="text-danger"> {{ goodsInfo.supplPrice }} </label>元
                    </label>
                  </div>
                </div>
                <div class="card-body pt-0">
                  <label class="fs-6 form-label fw-bolder text-dark required"
                  >市场价</label
                  >
                  <input
                      type="text"
                      name="weight"
                      id="weight"
                      class="form-control form-control-lg form-control-solid"
                      placeholder="市场价"
                      value=""
                      v-model="goodsInfo.price"
                  />
                  <label
                      v-if="((role.includes('firstGradeSupplier') || role.includes('specialFirstGrade')) && !goodsInfo.type)
                      || ((role.includes('firstGradeSupplier') || role.includes('specialFirstGrade')) && goodsInfo.type == 1)  || role.includes('secondTierSuppliers') || role.includes('vipMember')"
                      class="fs-6 form-label fw-bolder text-dark required">
                    分销价/供货价
                  </label>
                  <input
                      v-if="((role.includes('firstGradeSupplier') || role.includes('specialFirstGrade')) && !goodsInfo.type)
                      || ((role.includes('firstGradeSupplier') || role.includes('specialFirstGrade')) && goodsInfo.type == 1) || role.includes('secondTierSuppliers') || role.includes('vipMember')"
                      type="text"
                      name="weight"
                      id="weight"
                      class="form-control form-control-lg form-control-solid"
                      placeholder="分销价/供货价"
                      value=""
                      v-model="goodsInfo.supplPrice"
                  />
                  <label
                      v-if="((role.includes('firstGradeSupplier') || role.includes('specialFirstGrade'))|| role.includes('vipMember')) && goodsInfo.type == 2"
                      class="fs-6 form-label fw-bolder text-dark"
                  >分销价加价比例（%）</label
                  >
                  <input
                      v-if="((role.includes('firstGradeSupplier') || role.includes('specialFirstGrade')) || role.includes('vipMember'))&& goodsInfo.type == 2"
                      type="text"
                      name="weight"
                      id="weight"
                      class="form-control form-control-lg form-control-solid"
                      placeholder="请输入加价比例"
                      value=""
                      v-model="goodsInfo.ratio"
                  />

                  <label v-if="(role.includes('firstGradeSupplier') || role.includes('specialFirstGrade')) || role.includes('vipMember')"
                         class="fs-6 form-label fw-bolder text-dark required"
                  >销售价</label
                  >
                  <input v-if="(role.includes('firstGradeSupplier') || role.includes('specialFirstGrade')) || role.includes('vipMember')" type="text"
                         name="weight"
                         id="weight"
                         class="form-control form-control-lg form-control-solid"
                         placeholder="销售价"
                         value=""
                         v-model="goodsInfo.salePrice"
                  />
                </div>
              </div>
              <div class="card card-flush py-4">
                <div class="card-header">
                  <div class="card-title">
                    <h2>商品分类</h2>
                  </div>
                </div>
                <div class="card-body pt-0">
                  <label class="fs-6 form-label fw-bolder text-dark required"
                  >一级分类</label
                  >
                  <select
                      class="form-select form-select-solid"
                      v-model="goodsInfo.categoryId1"
                      ref="category1Name"
                      data-placeholder="全部"
                      @change="findCategory(1)"
                  >
                    <option value="">全部</option>
                    <option
                        v-for="(item, index) in categoryOne"
                        :key="index"
                        :value="item.categoryId"
                    >
                      {{ item.fullName }}
                    </option>
                  </select>
                  <label class="fs-6 form-label fw-bolder text-dark required"
                  >二级分类</label
                  >
                  <select
                      class="form-select form-select-solid"
                      v-model="goodsInfo.categoryId2"
                      ref="category2Name"
                      data-placeholder="全部"
                      @change="findCategory(2)"
                  >
                    <option value="">全部</option>
                    <option
                        v-for="(item, index) in categoryTwo"
                        :key="index"
                        :value="item.categoryId"
                    >
                      {{ item.fullName }}
                    </option>
                  </select>
                  <label
                      class="fs-6 form-label fw-bolder text-dark"
                      :class="categoryThree.length != 0 ? `required` : ``"
                  >三级分类</label
                  >
                  <select
                      class="form-select form-select-solid"
                      v-model="goodsInfo.categoryId3"
                      data-placeholder="全部"
                      ref="category3Name"
                      @change="findCategory(3)"
                  >
                    <option value="">全部</option>
                    <option
                        v-for="(item, index) in categoryThree"
                        :key="index"
                        :value="item.categoryId"
                    >
                      {{ item.fullName }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div
                class="flex-column flex-row-fluid gap-7 gap-lg-10"
                style="margin-right: 10px"
            >
              <ul
                  class="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2"
                  style="
                  background-color: #fff !important;
                  padding: 17px;
                  border-radius: 15px 15px 0 0;
                  margin-bottom: 0 !important;
                  padding-bottom: 2px !important;
                "
              >
                <li class="nav-item">
                  <a
                      class="nav-link text-active-primary pb-4 active"
                      data-bs-toggle="tab"
                      href="#kt_ecommerce_add_product_general"
                  >商品详情</a
                  >
                </li>
                <li class="nav-item">
                  <a
                      class="nav-link text-active-primary pb-4"
                      data-bs-toggle="tab"
                      href="#kt_ecommerce_add_product_advanced"
                  >规格</a
                  >
                </li>
              </ul>
              <div class="tab-content">
                <div
                    class="tab-pane fade show active"
                    id="kt_ecommerce_add_product_general"
                    role="tab-panel"
                >
                  <div class="d-flex flex-column gap-7 gap-lg-10">
                    <div
                        class="card card-flush py-4"
                        style="
                        border-radius: 0 0 15px 15px !important;
                      "
                    >
                      <div class="card-header">
                        <div class="card-title">
                          <h2 class="required">商品标题</h2>
                        </div>
                      </div>
                      <div class="card-body pt-0">
                        <div class="mb-10 fv-row">
                          <!-- <label class="required form-label">商品标题</label> -->
                          <input
                              type="text"
                              name="product_name"
                              class="form-control mb-2"
                              placeholder="商品标题"
                              value=""
                              v-model="goodsInfo.title"
                          />
                        </div>
                        <div class="card-body border-top p-9">
                          <div class="row mb-6">
                            <label class="col-lg-2 col-form-label fw-bold fs-6" :class="{
                                  required: permissionList.includes('goods:weight:required')
                                }">
                              <span>重量（kg）</span>
                            </label>
                            <div class="col-lg-4 fv-row">
                              <input
                                  type="text"
                                  name="weight"
                                  id="weight"
                                  class="form-control form-control-lg form-control-solid"
                                  placeholder="请输入重量"
                                  value=""
                                  v-model="goodsInfo.weight"
                              />
                            </div>
                            <label
                                class="col-lg-2 col-form-label fw-bold fs-6"
                                style="text-align: center"
                            >
                              <span class="required">库存</span>
                            </label>
                            <div class="col-lg-4 fv-row">
                              <input
                                  type="text"
                                  name="stock"
                                  id="stock"
                                  class="form-control form-control-lg form-control-solid"
                                  placeholder="请输入库存"
                                  value=""
                                  v-model="goodsInfo.stock"
                              />
                            </div>
                          </div>
                          <div class="row mb-6">
                            <label class="col-lg-2 col-form-label fw-bold fs-6"  :class="{
                                  required: permissionList.includes('goods:unit:required')
                                }">
                              <span>商品单位</span>
                            </label>
                            <div class="col-lg-4 fv-row">
                              <input
                                  type="text"
                                  name="weight"
                                  id="weight"
                                  class="form-control form-control-lg form-control-solid"
                                  placeholder="请输入商品单位"
                                  value=""
                                  v-model="goodsInfo.unit"
                              />
                            </div>
                            <label
                                class="col-lg-2 col-form-label fw-bold fs-6"
                                style="text-align: center"
                            >
                              <span class="required">发票类型</span>
                            </label>
                            <div class="col-lg-4 fv-row">
                              <select
                                  aria-label="Select a Timezone"
                                  id="isDelivery"
                                  name="isDelivery"
                                  data-placeholder="请选择发票类型"
                                  v-model="goodsInfo.invoiceType"
                                  class="form-select form-select-solid form-select-lg"
                                  @change="invoiceTypeChange"
                              >
                                <option value="">请选择</option>
                                <option :value="1">普通发票</option>
                                <option :value="2">增值税专用发票</option>
                              </select>
                            </div>
                          </div>
                          <div class="row mb-6">
                            <label class="col-lg-2 col-form-label fw-bold fs-6" :class="{
                                  required: permissionList.includes('goods:brand:required')
                                }">
                              <span>品牌名</span>
                            </label>
                            <div class="col-lg-4 fv-row" style="position: relative">
                              <div style="position: absolute;width: 100%;height: 100%;cursor: pointer"
                                   @click="selectBrand">

                              </div>
                              <input
                                  type="text"
                                  disabled=""
                                  name="brandName"
                                  id="brandName"
                                  class="form-control form-control-lg form-control-solid"
                                  placeholder="请输入品牌名"
                                  style="cursor: pointer"
                                  value=""
                                  v-model="goodsInfo.brandName"
                              />
                            </div>
                            <label
                                class="col-lg-2 col-form-label fw-bold fs-6"
                                style="text-align: center"
                                :class="{
                                  required: permissionList.includes('goods:brand:required')
                                }"
                            >
                              <span class="">品牌Id</span>
                            </label>
                            <div class="col-lg-4 fv-row" @click="selectBrand" style="position: relative">
                              <div style="position: absolute;width: 100%;height: 100%;cursor: pointer">

                              </div>
                              <input
                                  type="text"
                                  disabled=""
                                  name="brandId"
                                  id="brandId"
                                  class="form-control form-control-lg form-control-solid"
                                  placeholder="请输入品牌Id"
                                  value=""
                                  v-model="goodsInfo.brandId"
                              />
                            </div>
                          </div>
                          <div class="row mb-6">
                            <label
                                class="col-lg-2 col-form-label fw-bold fs-6"
                                :class="{
                                  required: permissionList.includes('goods:tax:code:required')
                                }"
                            >税收分类编码</label
                            >
                            <div class="col-lg-4 fv-row" style="position: relative">
                              <div style="position: absolute;width: 100%;height: 100%;cursor: pointer"
                                   @click="selectTax">

                              </div>
                              <input
                                  type="text"
                                  disabled=""
                                  name="brandName"
                                  id="brandName"
                                  class="form-control form-control-lg form-control-solid"
                                  placeholder="请输入税收分类编码"
                                  style="cursor: pointer"
                                  value=""
                                  v-model="goodsInfo.taxCode"
                              />
                            </div>
                            <label
                                class="col-lg-2 col-form-label fw-bold fs-6"
                                style="text-align: center"
                                :class="{
                                  required: permissionList.includes('goods:tax:code:required')
                                }"
                            >
                              <span class="">税收分类名称</span>
                            </label>
                            <div class="col-lg-4 fv-row" @click="selectTax" style="position: relative">
                              <div style="position: absolute;width: 100%;height: 100%;cursor: pointer">

                              </div>
                              <input
                                  type="text"
                                  disabled=""
                                  name="brandId"
                                  id="brandId"
                                  class="form-control form-control-lg form-control-solid"
                                  placeholder="请输入税收分类名称"
                                  value=""
                                  v-model="goodsInfo.taxName"
                              />
                            </div>
                          </div>
                          <div class="row mb-6">
                            <label
                                class="col-lg-2 col-form-label required fw-bold fs-6"
                            >运费模板</label
                            >
                            <div class="col-lg-4 fv-row">
                              <select
                                  id="freightId"
                                  name="freightId"
                                  v-model="goodsInfo.freightId"
                                  aria-label="Select a Language"
                                  data-placeholder="请选择运费模板"
                                  class="form-select form-select-solid form-select-lg"
                              >
                                <option value="">请选择模板</option>
                                <option
                                    :value="item.aId"
                                    v-for="(item, index) in freight"
                                    :key="index"
                                >
                                  {{ item.aName }}
                                </option>
                              </select>
                            </div>
                            <label
                                class="col-lg-2 col-form-label fw-bold fs-6"
                                style="text-align: center"
                            >
                              <span>商品编号</span>
                            </label>
                            <div class="col-lg-4 fv-row">
                              <input
                                  type="text"
                                  name="code"
                                  id="code"
                                  class="form-control form-control-lg form-control-solid"
                                  placeholder="请输入商品编号"
                                  value=""
                                  v-model="goodsInfo.code"
                              />
                            </div>
                          </div>
                          <div class="row mb-6">
                            <label
                                class="col-lg-2 col-form-label fw-bold fs-6"
                            >税率</label
                            >
                            <div class="col-lg-4 fv-row">
                              <select
                                  id="freightId"
                                  name="freightId"
                                  v-model="goodsInfo.taxRate"
                                  aria-label="Select a Language"
                                  data-placeholder="请选择税率"
                                  class="form-select form-select-solid form-select-lg"
                              >
                                <option value="">请选择税率</option>
                                <option value="0">0%</option>
                                <option value="3">3%</option>
                                <option value="6">6%</option>
                                <option value="9">9%</option>
                                <option value="13">13%</option>
                              </select>
                            </div>
                          </div>
                          <div class="row mb-6">
                            <label
                                class="col-lg-2 col-form-label required fw-bold fs-6"
                            >是否送货上门</label
                            >
                            <div class="col-lg-4 fv-row">
                              <select
                                  aria-label="Select a Timezone"
                                  id="isDelivery"
                                  name="isDelivery"
                                  data-placeholder="请选择是否有送货上门"
                                  v-model="goodsInfo.isDelivery"
                                  class="form-select form-select-solid form-select-lg"
                                  @change="getDeliveryList"
                              >
                                <option value="">请选择</option>
                                <option value="1">是</option>
                                <option value="2">否</option>
                              </select>
                            </div>
                            <label
                                v-show="goodsInfo.isDelivery === '1'"
                                class="col-lg-2 col-form-label required fw-bold fs-6"
                                style="text-align: center"
                                id="delivery_div1"
                            >送货上门模板</label
                            >
                            <div
                                v-show="goodsInfo.isDelivery === '1'"
                                class="col-lg-4 fv-row"
                                id="delivery_div2"
                            >
                              <select
                                  aria-label="Select a Timezone"
                                  id="delivery_div"
                                  name="delivery_div"
                                  data-placeholder="请选择送货上门模板"
                                  class="form-select form-select-solid form-select-lg"
                                  v-model="goodsInfo.deliveryId"
                              >
                                <option value="">请选择</option>
                                <option
                                    :value="item.aId"
                                    v-for="(item, index) in deliveryList"
                                    :key="index"
                                >
                                  {{ item.aName }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="row mb-6">
                            <label
                                class="col-lg-2 col-form-label required fw-bold fs-6"
                            >是否有上门安装</label
                            >
                            <div class="col-lg-4 fv-row">
                              <select
                                  name="isInstall"
                                  aria-label="Select a Timezone"
                                  id="isInstall"
                                  data-placeholder="请选择是否有上门安装"
                                  v-model="goodsInfo.isInstall"
                                  class="form-select form-select-solid form-select-lg"
                                  @change="getInstallSelect"
                              >
                                <option value="">请选择</option>
                                <option value="1">是</option>
                                <option value="2">否</option>
                              </select>
                            </div>
                            <label
                                v-show="goodsInfo.isInstall === '1'"
                                class="col-lg-2 col-form-label required fw-bold fs-6"
                                style="text-align: center"
                                id="install_div1"
                            >上门安装模板</label
                            >
                            <div
                                v-show="goodsInfo.isInstall === '1'"
                                class="col-lg-4 fv-row"
                                id="install_div2"
                            >
                              <select
                                  name="insId"
                                  id="insId"
                                  aria-label="Select a Timezone"
                                  data-placeholder="请选择上门安装模板"
                                  class="form-select form-select-solid form-select-lg"
                                  v-model="goodsInfo.installId"
                              >
                                <option value="">请选择</option>
                                <option
                                    :value="item.aId"
                                    v-for="(item, index) in installList"
                                    :key="index"
                                >
                                  {{ item.aName }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="row mb-6">
                            <label
                                class="col-lg-2 col-form-label required fw-bold fs-6"
                            >销售方式</label
                            >
                            <div class="col-lg-10 fv-row">
                              <div class="d-flex align-items-center mt-3">
                                <div
                                    class="col-lg-2 form-check form-check-inline form-check-solid me-5"
                                >
                                  <input
                                      v-model="isStartNum"
                                      class="form-check-input"
                                      name="buyType"
                                      type="radio"
                                      id="buyType1"
                                      :value="1"
                                      checked
                                  />
                                  <span class="fw-bold ps-2 fs-6"
                                  >一件代发</span
                                  >
                                </div>
                                <div
                                    class="col-lg-2 form-check form-check-inline form-check-solid"
                                >
                                  <input
                                      class="form-check-input"
                                      name="buyType"
                                      id="buyType2"
                                      type="radio"
                                      :value="2"
                                      v-model="isStartNum"
                                  />
                                  <span class="fw-bold ps-2 fs-6">集采</span>
                                </div>
                                <div class="col-lg-2">
                                  <input
                                      type="text"
                                      name="startNum"
                                      id="startNum"
                                      v-if="isStartNum != 1"
                                      class="form-control form-control-lg form-control-solid"
                                      placeholder="请输入起购数量"
                                      v-model="goodsInfo.startNum"
                                      value="1"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mb-6">
                            <label
                                class="col-lg-2 col-form-label required fw-bold fs-6"
                            >是否支持比价</label
                            >
                            <div class="col-lg-10 fv-row"  v-if="!permissionList.includes('goods:jdurl:required')">
                              <div class="d-flex align-items-center mt-3">
                                <label
                                    class="form-check form-check-inline form-check-solid me-5"
                                >
                                  <input
                                      class="form-check-input"
                                      name="comparePrice"
                                      type="radio"
                                      value="1"
                                      v-model="isJdUrl"
                                  />
                                  <span class="fw-bold ps-2 fs-6">支持</span>
                                </label>
                                <label

                                    class="form-check form-check-inline form-check-solid"
                                >
                                  <input
                                      class="form-check-input"
                                      name="comparePrice"
                                      type="radio"
                                      value="2"
                                      v-model="isJdUrl"
                                  />
                                  <span class="fw-bold ps-2 fs-6">不支持</span>
                                </label>
                                <div
                                    class="col-lg-8 fv-row"

                                    style="margin-left: 60px; width: 50%;position: relative"
                                >
                                  <input
                                      type="text"
                                      name="jdUrl"
                                      id="jdRow"
                                      v-if="isJdUrl == 1"
                                      class="form-control form-control-lg form-control-solid"
                                      placeholder="请输入该商品的京东链接"
                                      value=""
                                      v-model="goodsInfo.jdUrl"
                                  />
                                  <p style="color: #800020;font-size: 10px;position: absolute;bottom: -30px;left: 10px">
                                    * 填写比价链接，系统会优先推荐</p>
                                </div>

                              </div>
                            </div>
                            <div class="col-lg-10 fv-row"  v-else>
                              <div class="d-flex align-items-center mt-3">
                                <div
                                    class="col-lg-8 fv-row"

                                    style="width: 40%;position: relative"
                                >
                                  <input
                                      type="text"
                                      name="jdUrl"
                                      id="jdRow"
                                      v-if="isJdUrl == 1"
                                      class="form-control form-control-lg form-control-solid"
                                      placeholder="请输入该商品的京东链接"
                                      value=""
                                      v-model="goodsInfo.jdUrl"
                                  />
                                  <p style="color: #800020;font-size: 10px;position: absolute;bottom: -30px;left: 10px">
                                    * 填写比价链接，系统会优先推荐</p>
                                </div>

                              </div>
                            </div>
                          </div>
                          <div class="row mb-6">
                            <label
                                class="col-lg-2 col-form-label required fw-bold fs-6"
                            >是否支持7天无理由退货</label
                            >
                            <div class="col-lg-10 fv-row">
                              <div class="d-flex align-items-center mt-3">
                                <label
                                    class="form-check form-check-inline form-check-solid me-5"
                                >
                                  <input
                                      class="form-check-input"
                                      name="sevenNRR"
                                      type="radio"
                                      value="1"
                                      v-model="goodsInfo.sevenNRR"
                                  />
                                  <span class="fw-bold ps-2 fs-6">支持</span>
                                </label>
                                <label
                                    class="form-check form-check-inline form-check-solid"
                                >
                                  <input
                                      class="form-check-input"
                                      name="sevenNRR"
                                      type="radio"
                                      value="0"
                                      v-model="goodsInfo.sevenNRR"
                                  />
                                  <span class="fw-bold ps-2 fs-6">不支持</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="row mb-6">
                            <label
                                class="col-lg-2 col-form-label fw-bold fs-6"
                            >是否支持京东快递</label
                            >
                            <div class="col-lg-10 fv-row">
                              <div class="d-flex align-items-center mt-3">
                                <label
                                    class="form-check form-check-inline form-check-solid me-5"
                                >
                                  <input
                                      class="form-check-input"
                                      name="isJdLogistics"
                                      type="radio"
                                      :value="true"
                                      v-model="goodsInfo.isJdLogistics"
                                  />
                                  <span class="fw-bold ps-2 fs-6">支持</span>
                                </label>
                                <label
                                    class="form-check form-check-inline form-check-solid"
                                >
                                  <input
                                      class="form-check-input"
                                      name="isJdLogistics"
                                      type="radio"
                                      :value="false"
                                      v-model="goodsInfo.isJdLogistics"
                                  />
                                  <span class="fw-bold ps-2 fs-6">不支持</span>
                                </label>
                                <div
                                    class="col-lg-8 fv-row"
                                    v-if="goodsInfo.isJdLogistics"
                                    style="margin-left: 60px; width: 50%;position: relative"
                                >
                                  <input
                                      type="text"
                                      name="jdUrl"
                                      id="jdRow"

                                      class="form-control form-control-lg form-control-solid"
                                      placeholder="请输入该商品的京东快递运费"
                                      value=""
                                      v-model="goodsInfo.isJdLogisticsPrice"
                                  />
                                  <p style="color: #800020;font-size: 10px;position: absolute;bottom: -30px;left: 10px">
                                    * 仅展示使用</p>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div class="row mb-6">
                            <label
                                class="col-lg-2 col-form-label fw-bold fs-6"
                            >是否支持顺丰快递</label
                            >
                            <div class="col-lg-10 fv-row">
                              <div class="d-flex align-items-center mt-3">
                                <label
                                    class="form-check form-check-inline form-check-solid me-5"
                                >
                                  <input
                                      class="form-check-input"
                                      name="isSfLogistics"
                                      type="radio"
                                      :value="true"
                                      v-model="goodsInfo.isSfLogistics"
                                  />
                                  <span class="fw-bold ps-2 fs-6">支持</span>
                                </label>
                                <label
                                    class="form-check form-check-inline form-check-solid"
                                >
                                  <input
                                      class="form-check-input"
                                      name="isSfLogistics"
                                      type="radio"
                                      :value="false"
                                      v-model="goodsInfo.isSfLogistics"
                                  />
                                  <span class="fw-bold ps-2 fs-6">不支持</span>
                                </label>
                                <div
                                    class="col-lg-8 fv-row"
                                    v-if="goodsInfo.isSfLogistics"
                                    style="margin-left: 60px; width: 50%;position: relative"
                                >
                                  <input
                                      type="text"
                                      name="jdUrl"
                                      id="jdRow"

                                      class="form-control form-control-lg form-control-solid"
                                      placeholder="请输入该商品的顺丰快递运费"
                                      value=""
                                      v-model="goodsInfo.isSfLogisticsPrice"
                                  />
                                  <p style="color: #800020;font-size: 10px;position: absolute;bottom: -30px;left: 10px">
                                    * 仅展示使用</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mb-6">
                            <label
                                class="col-lg-2 col-form-label fw-bold fs-6"
                                :class="{
                                  required: permissionList.includes('goods:cn:code:required')
                                }"
                            >69码</label>
                            <div class="col-lg-10 fv-row">
                              <div class="d-flex align-items-center mt-3 col-lg-4 fv-row">
                                <input
                                    type="text"
                                    name="code"
                                    id="code"
                                    class="form-control form-control-lg form-control-solid"
                                    placeholder="请输入商品69码"
                                    value=""
                                    v-model="goodsInfo.cnCode"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row mb-6">
                            <label
                                class="col-lg-2 col-form-label required fw-bold fs-6"
                            >发货时效</label
                            >
                            <div class="col-lg-10 fv-row">
                              <div class="d-flex align-items-center mt-3">
                                <label
                                    class="form-check form-check-inline form-check-solid me-5"
                                >
                                  <input
                                      class="form-check-input"
                                      id="deliveryTime1"
                                      name="deliveryTime"
                                      type="radio"
                                      value="24小时内发货"
                                      v-model="goodsInfo.deliveryTime"
                                  />
                                  <span class="fw-bold ps-2 fs-6"
                                  >24小时内发货</span
                                  >
                                </label>
                                <label
                                    class="form-check form-check-inline form-check-solid"
                                >
                                  <input
                                      class="form-check-input"
                                      id="deliveryTime2"
                                      name="deliveryTime"
                                      type="radio"
                                      value="48小时内发货"
                                      v-model="goodsInfo.deliveryTime"
                                  />
                                  <span class="fw-bold ps-2 fs-6"
                                  >48小时内发货</span
                                  >
                                </label>
                                <label
                                    class="form-check form-check-inline form-check-solid"
                                >
                                  <input
                                      class="form-check-input"
                                      id="deliveryTime3"
                                      name="deliveryTime"
                                      type="radio"
                                      value="48小时内发货(周末、节假日除外)"
                                      v-model="goodsInfo.deliveryTime"
                                  />
                                  <span class="fw-bold ps-2 fs-6"
                                  >48小时内发货(周末、节假日除外)</span
                                  >
                                </label>

                                <label
                                    class="form-check form-check-inline form-check-solid"
                                >
                                  <input
                                      class="form-check-input"
                                      id="deliveryTime4"
                                      name="deliveryTime"
                                      type="radio"
                                      value="72小时内发货"
                                      v-model="goodsInfo.deliveryTime"
                                  />
                                  <span class="fw-bold ps-2 fs-6"
                                  >72小时内发货</span
                                  >
                                </label>

                                <label
                                    class="form-check form-check-inline form-check-solid"
                                >
                                  <input
                                      class="form-check-input"
                                      id="deliveryTime5"
                                      name="deliveryTime"
                                      type="radio"
                                      value="其他"
                                      v-model="goodsInfo.deliveryTime"
                                  />
                                  <span class="fw-bold ps-2 fs-6">其他</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card card-flush py-4">
                      <div class="card-header">
                        <div class="card-title">
                          <h2 class="required">轮播图管理</h2>
                        </div>
                      </div>
                      <div style="margin: 20px">
                        <UploadFile
                            moduleName="moduleName"
                            accept=".png,.jpg,.jpeg"
                            @upload-success="uploadSuccess"
                        ></UploadFile>
                        <div class="imgList">
                          <div
                              v-for="(item, index) in imgList"
                              :key="index"
                              class="imgItem"
                          >
                            <img width="300px" alt="" :src="item"/>
                            <button
                                @click="removeImages(item, index)"
                                class="removeImage"
                            >
                              <i class="el-icon-close"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card card-flush py-4">
                      <div class="card-header">
                        <div class="card-title">
                          <h2 class="required">商品详情</h2>
                        </div>
                      </div>
                      <div style="margin: 20px">
                        <Tinymce
                            v-if="status == 0"
                            ref="editor"
                            v-model="goodsInfo.detail"
                            :height="400"
                        ></Tinymce>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                    class="tab-pane fade"
                    id="kt_ecommerce_add_product_advanced"
                    role="tab-panel"
                >
                  <div class="d-flex flex-column gap-7 gap-lg-10">
                    <div
                        class="card card-flush py-4"
                        style="border-radius: 0 0 15px 15px !important"
                    >
                      <div class="card card-flush py-4">
                        <div class="card-body pt-0">
                          <div
                              class=""
                              data-kt-ecommerce-catalog-add-product="auto-options"
                          >
                            <div id="kt_ecommerce_add_product_options">
                              <div class="form-group">
                                <div
                                    data-repeater-list="kt_ecommerce_add_product_options"
                                    class="d-flex flex-column gap-3"
                                >
                                  <div
                                      class="card-title"
                                      v-if="goodsInfo.spuId == '' || (goodsInfo.saleAttrVo != null && goodsInfo.saleAttrVo != '')"
                                  >
                                    <h2>销售规格</h2>
                                  </div>
                                  <!--v-if="goodsInfo.spuId == ''" 同spu下商品规格必需相同限制-->
                                  <div class="form-group mt-5">
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-light-primary"
                                        @click="addValues"
                                        v-if="goodsInfo.spuId == ''"
                                    >
                                      <i class="ki-outline ki-plus fs-2"></i
                                      >添加销售规格
                                    </button>
                                  </div>
                                  <div
                                      v-for="(item, index) in arr"
                                      :key="index"
                                      data-repeater-item=""
                                      class="form-group d-flex flex-wrap align-items-center gap-5"
                                  >
                                    <!--:disabled="goodsInfo.spuId != ''" 禁止修改-->
                                    <div class="w-100 w-md-200px">
                                      <input
                                          title="属性名称：颜色"
                                          type="text"
                                          name="product_option_value"
                                          v-model="arr[index]['attrName']"
                                          class="form-control mw-100 w-200px"
                                      />
                                    </div>
                                    <div class="w-100 w-md-200px">
                                      <input
                                          title="属性值: 黑色"
                                          type="text"
                                          name="product_option_value"
                                          v-model="arr[index]['attrValue']"
                                          class="form-control mw-100 w-200px"
                                      />
                                    </div>
                                    <button
                                        type="button"
                                        data-repeater-delete=""
                                        @click="removeValue(index)"
                                        v-if="goodsInfo.spuId == ''"
                                        class="btn btn-sm btn-icon btn-light-danger"
                                    >
                                      <i class="ki-outline ki-cross fs-1"></i>
                                    </button>
                                  </div>
                                </div>
                                <br/>
                                <div class="card-title"
                                     v-if="specialSpecArr.length != 0"
                                >
                                  <h2>规格详情</h2>
                                </div>
                                <div
                                    data-repeater-list="kt_ecommerce_add_product_options"
                                    class="d-flex flex-column gap-3"
                                >
                                  <!-- <div class="form-group mt-5">
                                    <button
                                      type="button"
                                      data-repeater-create=""
                                      class="btn btn-sm btn-light-primary"
                                    >
                                      <i class="ki-outline ki-plus fs-2"></i
                                      >添加规格介绍
                                    </button>
                                  </div> -->
                                  <div
                                      v-for="(item, index) in specialSpecArr"
                                      :key="index"
                                      data-repeater-item=""
                                      class="form-group d-flex flex-wrap align-items-center gap-5"
                                  >
                                    <input
                                        type="text"
                                        class="form-control mw-100 w-200px"
                                        name="product_option_value"
                                        :placeholder="item.key"
                                        disabled
                                    />
                                    <div class="w-100 w-md-200px">
                                      <select
                                          class="form-select"
                                          name="product_option"
                                          data-placeholder="Select a variation"
                                          data-kt-ecommerce-catalog-add-product="product_option"
                                          v-model="item.name"
                                      >
                                        <option></option>
                                        <option
                                            :value="its"
                                            v-for="(its, i) in item.value"
                                            :key="i"
                                        >
                                          {{ its }}
                                        </option>
                                      </select>
                                    </div>
                                    <!-- <button
                                    type="button"
                                    data-repeater-delete=""
                                    title="提价销售规格参数"
                                    class="btn btn-sm btn-icon btn-light-primary"
                                  >
                                    <i class="ki-outline ki-plus fs-1"></i>
                                  </button> -->
                                    <!-- <button
                                      type="button"
                                      data-repeater-delete=""
                                      class="btn btn-sm btn-icon btn-light-danger"
                                    >
                                      <i class="ki-outline ki-cross fs-1"></i>
                                    </button> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end mt-2">
                <a
                    href="javascript:void(0)"
                    id="kt_ecommerce_add_product_cancel"
                    class="btn btn-light me-5"
                    @click="$router.push('/goods')"
                >取消</a
                >
                <!--                <button
                                    v-if="!(role.includes('firstGradeSupplier') && goodsInfo.type == 2)"
                                    type="submit"
                                    id="kt_ecommerce_add_product_submit"
                                    class="btn text-light text-bg-info text-hover-muted me-5"
                                    @click="saveGoodsInfo(1)"
                                >
                                  <span class="indicator-label">提交并保留当前数据</span>
                                  <span class="indicator-progress"
                                  >Please wait...
                                    <span
                                        class="spinner-border spinner-border-sm align-middle ms-2"
                                    ></span
                                    ></span>
                                </button>-->
                <button
                    type="submit"
                    id="kt_ecommerce_add_product_submit"
                    class="btn text-light text-bg-info text-hover-muted"
                    @click="saveGoodsInfo(0)"
                >
                  <span class="indicator-label">提交</span>
                  <span class="indicator-progress"
                  >Please wait...
                    <span
                        class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span
                    ></span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <ModalDialog ref="Assistant" title="上架小助手">
      <div class="row mb-2">
        <!--begin::Label-->
        <label class="col-lg-3 fw-bold text-muted" style="margin-top: 10px"
        >*京东链接/商品码:</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-9">
          <input
              id="goodsUrl"
              name="goodsUrl"
              v-model="goodsUrl"
              class="form-control form-control-solid"
              placeholder="请输入商品链接/商品码"
          />
        </div>
        <!--end::Col-->
        <div class="separator separator-dashed my-2"></div>
      </div>
      <div class="row mb-2">
        <!--begin::Label-->
        <label class="fw-bold text-muted" style="margin-top: 10px"
        >示例:https://item.jd.com/100044924745.html</label
        >
        <!--end::Label-->
      </div>
      <!--begin::Input group-->
      <div class="row fv-row mb-7 fv-plugins-icon-container"></div>
      <div class="modal-footer">
        <button
            type="button"
            class="btn btn-light"
            @click="$refs.Assistant.close()"
        >
          关闭
        </button>
        <!-- <button onclick="submitSendGoodsBatchModal();" type="button"
                                            class="btn btn-primary">提交</button> -->

        <button type="button" class="btn btn-primary" @click="assistant">
          提交
        </button>
      </div>
    </ModalDialog>

    <el-dialog title="选择品牌" :visible.sync="brandDialogFormVisible" width="30%">
      <el-select
          @change="changeBrand"
          v-model="goodsInfo.brandId"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词"
          :remote-method="remoteMethod"
          :loading="loading"
      >
        <el-option
            v-for="item in brandList"
            :key="item.brandId"
            :label="item.brandName"
            :value="item.brandId">
        </el-option>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button @click="brandDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="selectBrandItem">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="选择税收分类编码" :visible.sync="raxDialogFormVisible" width="30%">
      <el-select
          @change="changeTax"
          v-model="taxObj.serviceName"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词"
          :remote-method="remoteTaxMethod"
          :loading="loading"
      >
        <el-option
            v-for="item in taxOptions"
            :key="item.codeNum"
            :label="item.serviceName"
            :value="item.codeNum">
              <span style="float: left">{{ item.codeNum }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.serviceName }}</span>
        </el-option>
      </el-select>
      <div style="margin-top: 10px">
        {{taxObj.taxExplan}}
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="raxDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="selectTaxItem">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {addOrUpdateGoods} from "./addOrUpdateGoods.js"

export default {
  // key不能动
  mixins: [addOrUpdateGoods]
};
</script>

<!--<script>
import { removeFileApi, uploadFileApi } from "@/api/uploadFileApi";
import { getCategoryList, getValueListApi } from "@/api/categoryApi";
import { getFreightSelectApi } from "@/api/freightApi";
import { getDeliveryListApi } from "@/api/deliveryApi";
import { getInstallSelectApi } from "@/api/install";
import { deliveryTime } from "@/utils/goodsUtil";
import {
  getGoodsAssistantApi,
  getGoodsInfoApi,
  saveSjGoodsApi,
} from "@/api/goodsManageApi";
import Tinymce from "@/components/Tinymce/index.vue";

export default {
  props: {},
  data() {
    return {
      isStartNum:1,
      deliveryTime,
      imgList: [],
      categoryOne: [],
      categoryTwo: [],
      categoryThree: [],
      deliveryList: [],
      freight: [],
      installList: [],
      isJdUrl: "1",
      goodsUrl: "",
      // 商品信息
      goodsInfo: {
        id: "",
        spuId: "",
        title: "",
        spuTitle: "",
        categoryId1: "",
        categoryId2: "",
        categoryId3: "",
        categoryId1Name: "",
        categoryId2Name: "",
        categoryId3Name: "",
        detail: "",
        specialSpec: "",
        price: "",
        supplPrice: "",
        salePrice: "",
        jdUrl: "",
        comparePrice: "1",
        buyType: "1",
        startNum: "1",
        isInstall: "",
        isDelivery: "",
        deliveryId: "",
        freightId: "",
        stock: "",
        weight: "",
        picUrl: "",
        sevenNRR: "1",
        isShow: "",
        isQuan: "",
        saleAttrs: [],
        sjGoodsFile: [],
        deliveryTime: "24小时内发货",
      },
      valueSize: 0,
      valueList: [],
      arr: [],
      specialSpec: null,
      specialSpecObj: {},
      specialSpecArr: [],
      status: 0,
      goodsStatus: "",
      role: [],
    };
  },
  methods: {
    //移除图片
    async removeImages(item, index) {
      this.imgList.splice(index, 1);
      if ( this.goodsInfo.spuId != "" && this.goodsInfo.id == ""){
        return;
      } else{
        await removeFileApi({
          url: item,
        });
      }
      // if ((this.goodsInfo.spuId =="" && this.goodsInfo.id =="") || (this.goodsInfo.spuId !="" && this.goodsInfo.id !="") ) {

      // }
    },
    // 移除销售规格
    removeValue(index) {
      this.arr.splice(index, 1);
    },
    //保存
    async saveGoodsInfo(e) {
      if (e === 1){
        this.goodsInfo.id = null;
      }

      this.goodsInfo.sjGoodsFile = [];
      let obj = {};
      if (this.specialSpecArr){
        this.specialSpecArr.forEach((e, index) => {
          if (e.name){
            obj[e.key] = e.name;
          }
        });
        this.goodsInfo.specialSpec = JSON.stringify(obj);
      } else {
        this.goodsInfo.specialSpec = null;
      }

      this.goodsInfo.saleAttrs = this.arr;

      let categoryId1Name = this.$refs.category1Name;
      let categoryId1Index = categoryId1Name.selectedIndex;

      if (this.goodsInfo.categoryId1) {
        this.goodsInfo.categoryId1Name =
          categoryId1Name.options[categoryId1Index].text;
      } else {
        this.goodsInfo.categoryId1Name = "";
        this.goodsInfo.categoryId1 = "";
      }

      let categoryId2Name = this.$refs.category2Name;
      let categoryId2Index = categoryId2Name.selectedIndex;
      if (this.goodsInfo.categoryId2) {
        this.goodsInfo.categoryId2Name =
          categoryId2Name.options[categoryId2Index].text;
      } else {
        this.goodsInfo.categoryId2Name = "";
        this.goodsInfo.categoryId2 = "";
      }

      let categoryId3Name = this.$refs.category3Name;
      let categoryId3Index = categoryId3Name.selectedIndex;
      if (this.categoryThree.length!=0) {
        this.goodsInfo.categoryId3Name =
          categoryId3Name.options[categoryId3Index].text;
      } else {
        this.goodsInfo.categoryId3Name = "";
        this.goodsInfo.categoryId3 = "";
      }
      if (this.goodsInfo.buyType == 1) {
        this.goodsInfo.startNum = 1;
      }

      this.imgList.forEach((item, index) => {
        this.goodsInfo.sjGoodsFile.push({ name: index, url: item });
      });
      if (this.goodsStatus == 1) {
        this.goodsInfo.id = "";
      }
      if (!this.ruleForm()) {
        return;
      }
      if (this.isStartNum==1) {
        this.goodsInfo.startNum=1
      }
      let { data } = await saveSjGoodsApi(this.goodsInfo);
      this.goodsInfo.spuId = data.spuId;
      this.$swal.fire({
        icon: "success",
        title: "保存成功",
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 2000,
      });
      if (e == 0) {
        this.$router.push("/goods");
      }
    },
    // 表单规则
    ruleForm() {
      console.log(this.goodsInfo.picUrl)
      //检查主图
      if (!this.goodsInfo.picUrl) {
        this.$swal.fire({
          icon: "warning",
          title: "请上传商品主图",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });
        return false;
      }
      //检查市场价
      if (!this.goodsInfo.price) {
        this.$swal.fire({
          icon: "warning",
          title: "请填写商品市场价",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });
        return false;
      }
      //检查分销价
      if (!this.goodsInfo.supplPrice) {
        this.$swal.fire({
          icon: "warning",
          title: "请填写商品分销价",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });
        return false;
      }
      //检查销售价
      if (!this.goodsInfo.salePrice) {
        this.$swal.fire({
          icon: "warning",
          title: "请填写商品销售价",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });
        return false;
      }
      //检查一级分类
      if (!this.goodsInfo.categoryId1) {
        this.$swal.fire({
          icon: "warning",
          title: "请选择一级分类",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });
        return false;
      }
      //检查二级分类
      if (!this.goodsInfo.categoryId2) {
        this.$swal.fire({
          icon: "warning",
          title: "请选择二级分类",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });
        return false;
      }
      //检查当存在三级分类是判断是否选择三级分类
      if (this.categoryThree.length!=0 && this.goodsInfo.categoryId3=="") {
        this.$swal.fire({
          icon: "warning",
          title: "请选择三级分类",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });
        return false;
      }
      //检查商品标题
      if (!this.goodsInfo.title) {
        this.$swal.fire({
          icon: "warning",
          title: "请填写商品标题",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });
        return false;
      }
      //检查库存
      if (!this.goodsInfo.stock) {
        this.$swal.fire({
          icon: "warning",
          title: "请填写商品库存",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });
        return false;
      }
      //检查运费模板
      if (!this.goodsInfo.freightId) {
        this.$swal.fire({
          icon: "warning",
          title: "请选择运费模板",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });
        return false;
      }
      //检查送货上门
      if (!this.goodsInfo.isDelivery) {
        this.$swal.fire({
          icon: "warning",
          title: "请选择是否送货上门",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });
        return false;
      }
      //检查送货上门模板
      if (this.goodsInfo.isDelivery=="1"&&this.goodsInfo.deliveryId=="") {
        this.$swal.fire({
          icon: "warning",
          title: "请选择送货上门模板",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });
        return false;
      }

      // goodsInfo.deliveryId
      //检查是否有上门安装
      if (!this.goodsInfo.isInstall) {
        this.$swal.fire({
          icon: "warning",
          title: "请选择是否有上门安装",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });
        return false;
      }
      //检查有上门安装模板
      if (this.goodsInfo.isInstall=="1"&&this.goodsInfo.installId=="") {
        this.$swal.fire({
          icon: "warning",
          title: "请选择送货上门模板",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });
        return false;
      }
      //检查是否支持比价
      if (this.isJdUrl==1 && this.goodsInfo.jdUrl=="") {
        this.$swal.fire({
          icon: "warning",
          title: "请填写比价链接",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });
        return false;
      }
      //检查商品详情
      if (!this.goodsInfo.detail) {
        this.$swal.fire({
          icon: "warning",
          title: "请填写商品详情",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });
        return false;
      }
      //检查轮播图
      if (!this.imgList.length) {
        this.$swal.fire({
          icon: "warning",
          title: "请上传轮播图",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });
        return false;
      }
      //检查销售方式
      if (this.isStartNum==2 && this.goodsInfo.startNum=="") {
        this.$swal.fire({
          icon: "warning",
          title: "请填写集采数量",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });
        return false;
      }

      return true;
    },
    // saveGoodsInfo() {},
    uploadSuccess(res, fileList) {
      this.imgList.push(res.data.fileUrl);
    },
    //上传文件
    async uploadFileAvatar(event) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("moduleName", "goodsAvatar");
      let { data } = await uploadFileApi(formData);
      this.goodsInfo.picUrl = data.fileUrl;
    },
    //初始化分类
    async initCategory(level,item) {
      let { data } = await getCategoryList({
        parentId: item || 0,
      });
      if (level == '' || level == null) {
        this.categoryOne = data.list;
      }
      if (level === 2) {
        this.categoryTwo = data.list;
      }
      if (level === 3) {
        this.categoryThree = data.list;
      }
    },
    //移除主图
    romverAvatar() {
      this.goodsInfo.picUrl = "";
    },
    async findCategory(index) {
      if (index == 1) {

        if (this.goodsInfo.categoryId1 != "" && this.goodsInfo.categoryId1 != null) {
          this.categoryTwo = [];
          this.categoryThree = [];
          this.goodsInfo.categoryId2 = "";
          this.goodsInfo.categoryId3 = "";
          let { data } = await getCategoryList({
            parentId: this.goodsInfo.categoryId1,
          });
          this.categoryTwo = data.list;
        } else {
          this.initCategory();
        }
      }

      if (index == 2) {

        if (this.goodsInfo.categoryId2 != "" && this.goodsInfo.categoryId2 != null) {
          this.categoryThree = [];
          this.goodsInfo.categoryId3 = "";
          let { data } = await getCategoryList({
            parentId: this.goodsInfo.categoryId2,
          });
          this.categoryThree = data.list;
          if (this.categoryThree.length == 0) {
            this.getValueList();
          }
        }
      }
      if (index == 3) {
        if (!this.goodsInfo.categoryId3) {
          this.goodsInfo.categoryId3 = "";
          this.goodsInfo.categoryId3Name = "";
        }
        this.getValueList();
      }
    },
    //获取运费模板
    async getFreightSelect() {
      let {
        data: { area },
      } = await getFreightSelectApi();
      this.freight = area;
    },
    async getDeliveryList() {
      let { data } = await getDeliveryListApi();
      this.deliveryList = data.area;
    },
    // 获取上门安装
    async getInstallSelect() {
      let { data } = await getInstallSelectApi();
      this.installList = data.area;
    },
    //获取商品规格
    async getValueList(index) {
      if (!this.goodsInfo.categoryId3) {
        return;
      }
      let categoryId3;
      if (this.categoryThree.length == 0) {
        categoryId3 = this.goodsInfo.categoryId2;
      } else {
        categoryId3 = this.goodsInfo.categoryId3;
      }

      let { data } = await getValueListApi({
        attrType: 1,
        categoryId3,
      });
      let arr = [];
      data.forEach((element) => {
        arr.push({
          key: element.key,
          value: eval(element.value),
          name: "",
        });
      });
      this.specialSpecArr = arr;
      this.specialSpec = data;
    },
    assistant() {
      if (this.goodsUrl == "") {
        Message({
          message: "请输入京东商品链接",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }
      let url = encodeURIComponent(this.goodsUrl);
      this.$router.push({
        name: "AddOrUpdateVisible",
        params: { id: url },
      });
    },
    addValues() {
      this.valueSize++;
      this.arr.push({ attrId: this.valueSize, attrName: "", attrValue: "" });
    },
    async getGoodsInfo(id) {
      let { data } = await getGoodsInfoApi({ id });
      this.goodsInfo = data;
      this.initCategory(2,data.categoryId1)
      await this.initCategory(3,data.categoryId2)
      this.getFreightSelect();
      this.getInstallSelect();
      this.getDeliveryList();
      await this.getValueList(2);
      if (this.goodsInfo.saleAttrVo){
        this.arr = this.goodsInfo.saleAttrVo;
      }
      if (data.jdUrl == "") {
        this.isJdUrl = "2";
      } else {
        this.isJdUrl = "1";
      }
      if (data.startNum==1) {
        this.isStartNum=1
      }else{
        this.isStartNum=2
      }
      if (this.specialSpecArr.length > 0 && this.goodsInfo.specialSpec){
        this.specialSpecArr.forEach((e, index) => {
          let json = JSON.parse(`{${data.specialSpec}}`);
          let key = e.key;
          this.specialSpecArr[index].name = json[key];
        });
      }
      if (data.sjGoodsFile.length > 0){
        data.sjGoodsFile.forEach((e, index) => {
          this.imgList.push(e.url);
        });
      }
    },
    //上架小助手
    async goodsAssistant(url) {
      url = decodeURIComponent(url);
      let id = url.match(/(\d+)/)[0];
      let { data } = await getGoodsAssistantApi({ id });
      data.spuId = '';
      this.goodsInfo = data;
      this.initCategory(2,data.categoryId1)
      await this.initCategory(3,data.categoryId2)
      this.goodsInfo.saleAttrs = [];
      this.getFreightSelect();
      this.getDeliveryList();
      this.goodsInfo.freightId = "";
      await this.getValueList(2);
      if (data.jdUrl == "") {
        this.isJdUrl = "2";
      } else {
        this.isJdUrl = "1";
        this.goodsInfo.jdUrl = url;
      }
      this.goodsInfo.isDelivery = "2";
      this.goodsInfo.isInstall = "2";
      if (this.specialSpecArr.length > 0 && this.goodsInfo.specialSpec) {
        this.specialSpecArr.forEach((e, index) => {
          let json = JSON.parse(`${data.specialSpec}`);
          let key = e.key;
          this.specialSpecArr[index].name = json[key];
        });
      }
      if (data.sjGoodsFile.length > 0){
        data.sjGoodsFile.forEach((e, index) => {
          this.imgList.push(e.url);
        });
      }
    },
    getLoginRole() {
      this.role = localStorage.getItem('role')
      console.log(this.role)
    }
  },
  created() {
    this.getLoginRole();
    this.initCategory();
    this.getFreightSelect();
    if (this.$route.params.id) {
      let id = this.$route.params.id;
      if (!isNaN(id)) {
        let status = this.$route.params.status;
        this.goodsStatus = status;
        this.getGoodsInfo(id);
      } else {
        this.goodsAssistant(id);
      }
    }
  },
  mounted() {},
  components: { uploadFileApi, Tinymce },
};
</script>-->

<style scoped>
.imgList {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-around; */
}

.imgItem {
  width: 200px;
  aspect-ratio: 800/780;
  padding: 1px;
  margin-top: 24px;
  position: relative;
  margin-right: 50px;
}

.imgItem img {
  width: 100%;
  height: 100%;
}

.removeImage {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 25px;
  height: 25px;
  background: #ffffffc9;
  border-radius: 50%;
  border: 0;
  text-align: center;
  line-height: 25px;
}
</style>
