<!-- 商品列表 -->
<template>
  <div id="kt_post" class="post flex-column-fluid">
    <VipPayComponent ref="VipPayComponent" @checkPay="checkPay"></VipPayComponent>
    <TitleCard title="商品管理" class="mt-5 mb-5" style="margin: 0 auto; max-width: 97%; border-radius: 15px">
      <template v-slot:subTitle class="position-relative">
        <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
          <li class="breadcrumb-item text-muted" rel="stylesheet"
              href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"
              style="font-family: 'Roboto', sans-serif">
            Products Management
          </li>
        </ul>
        <div style="margin-left: 35px">
          <el-radio-group v-model="index" size="small">
            <el-radio-button :label="1">&nbsp;&nbsp;&nbsp;&nbsp;自有商品&nbsp;&nbsp;&nbsp;&nbsp;</el-radio-button>
            <el-radio-button :label="2">&nbsp;&nbsp;&nbsp;&nbsp;已上架商品&nbsp;&nbsp;&nbsp;&nbsp;</el-radio-button>
          </el-radio-group>
        </div>
      </template>
    </TitleCard>
    <div id="kt_content_container" class="container"
         style="max-width: 98%; flex-direction: row !important; display: flex">
      <div id="kt_app_main" class="app-main flex-column flex-row-fluid">
        <div class="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" class="app-content" style="padding-top: 0px">
            <div class="card card-flush">
              <div class="card-body" style="padding: 17px 17px">
                <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x border-transparent fs-4 fw-semibold mb-15"
                    style="margin-bottom: 0 !important; position: relative;margin-left: 20px">
                  <div class="d-flex align-items-center py-3">
                    <!--begin::Button-->

                    <div v-if="index === 1" style="position: absolute; right: 0; padding: 0px 30px">

                      <el-button size="small" type="danger" @click="$refs.Assistant.open()">
                        上架小助手
                      </el-button>
                      <el-button size="small" type="primary"
                                 @click="$router.push({ path: 'goodsManage/addOrUpdateGoods' })">
                        创建自有商品
                      </el-button>
                      <a class="mx-2" href="javascript:void(0)">
                        <el-dropdown @command="templateManagementCommand">
                          <el-button size="small" type="primary">
                            模板管理<i class="el-icon-arrow-down el-icon--right"></i>
                          </el-button>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="freightTemplate">运费模板</el-dropdown-item>
                            <el-dropdown-item command="doorToDoorDelivery">送货上门</el-dropdown-item>
                            <el-dropdown-item command="doorToDoorInstallation">上门安装</el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </a>
                      <el-button size="small" type="primary"
                                 @click="$router.push({ path: 'goods/goods_group' })">
                        分组管理
                      </el-button>
                      <el-button size="small" type="primary"
                                 @click="$router.push({ path: 'goods/comboGiftPack' })">
                        组合礼包
                      </el-button>
                      <el-button size="small" type="primary"
                                 @click="exportTool">
                        导出工具
                      </el-button>
                      <a href="javascript:void(0)" class="mx-2">
                        <el-dropdown @command="batchOperations">
                          <el-button size="small" type="primary">
                            批量操作<i class="el-icon-arrow-down el-icon--right"></i>
                          </el-button>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-if="canUseExportGoods2Other()"
                                              command="push">挑选并推送商品
                            </el-dropdown-item>
                            <el-dropdown-item command="sevenNrr">修改七天无理由
                            </el-dropdown-item>
                            <el-dropdown-item command="freight">修改运费模板
                            </el-dropdown-item>
                            <el-dropdown-item command="listing">修改状态上架
                            </el-dropdown-item>
                            <el-dropdown-item command="removeFromShelves">修改状态下架
                            </el-dropdown-item>
                            <el-dropdown-item command="delete">删除商品
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </a>
                    </div>
                  </div>
                </ul>
                <!--    自有商品     -->
                <OwnGoods v-if="index === 1" @editEvent="gotoGoods" @setArrId="setArrId" ref="OwnGoods">
                </OwnGoods>
                <!--    供应商品    -->
                <SelectionGoods v-if="index === 2" ref="SelectionGoods"></SelectionGoods>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalDialog ref="Assistant" title="上架小助手">
      <div class="row mb-2">
        <!--begin::Label-->
        <label class="col-lg-3 fw-bold text-muted" style="margin-top: 10px">*京东链接/商品码:</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-9">
          <input id="goodsUrl" name="goodsUrl" v-model="goodsUrl" class="form-control form-control-solid"
                 placeholder="请输入商品链接/商品码"/>
        </div>
        <!--end::Col-->
        <div class="separator separator-dashed my-2"></div>
      </div>
      <div class="row mb-2">
        <el-tooltip content="复制链接到剪贴板" placement="top">
          <!--begin::Label-->
          <label class="fw-bold text-muted cursor-pointer" style="margin-top: 10px"
                 @click="goodsUrl = 'https://item.jd.com/100044924745.html'">示例:https://item.jd.com/100044924745.html&nbsp;&nbsp;&nbsp;<span
              class="text-primary">点击复制</span></label>
        </el-tooltip>
      </div>
      <!--begin::Input group-->
      <div class="row fv-row mb-7 fv-plugins-icon-container"></div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" @click="$refs.Assistant.close()">
          关闭
        </button>
        <button type="button" class="btn btn-primary" @click="assistant">
          提交
        </button>
      </div>
    </ModalDialog>

    <el-dialog title="批量修改七天无理由" :visible.sync="sevenNrrVisible" width="30%">
      <el-switch v-model="value1" active-text="支持" inactive-text="不支持" :active-value="1" :inactive-value="0">
      </el-switch>
      <span slot="footer" class="dialog-footer">
				<el-button @click="sevenNrrVisible = false">取 消</el-button>
				<el-button type="primary" @click="batchSevenNrr">保 存</el-button>
			</span>
    </el-dialog>

    <el-dialog title="批量修改运费模板" :visible.sync="fVisible" width="30%">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="运费模板">
          <el-select v-model="form.freightId" placeholder="请选择运费模板">
            <el-option v-for="(item, index) in freightList" :key="index" :label="item.title"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
				<el-button @click="fVisible = false">取 消</el-button>
				<el-button type="primary" @click="batchFreight">保 存</el-button>
			</span>
    </el-dialog>
    <!-- 推送规则 -->
    <ModalDialog title="挑选上架" id="pushGoodsAll" ref="pushGoodsAll" width="850px">
      <div>
        <el-form ref="form" :model="pushGoodsObj" label-width="80px">
          <el-form-item label="推送目标" :required="true">
            <el-checkbox-group v-model="pushGoodsObj.selectionObjects" @change="changeSelectionObjects">
              <el-checkbox label="group" :disabled="pushGoodsObj.selectionObjects.includes('topic')
								|| pushGoodsObj.selectionObjects.includes('shop') || pushGoodsObj.salePriceChange == 1">分组
              </el-checkbox>
              <el-checkbox label="shop"
                           :disabled="pushGoodsObj.selectionObjects.includes('group')">商城
              </el-checkbox>
              <el-checkbox label="topic"
                           :disabled="pushGoodsObj.selectionObjects.includes('group')">活动
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="已选商品">
            <span style="color: red">{{ state ? "1" : idArr.length }} </span> 件商品
          </el-form-item>
          <el-form-item label="销售价" :required="pushGoodsObj.salePriceChange == '1'">
            <el-radio-group v-model="pushGoodsObj.salePriceChange">
              <el-radio label="1" :disabled="pushGoodsObj.selectionObjects == 'group'">按照</el-radio>
            </el-radio-group>
            &nbsp;&nbsp;&nbsp;
            <el-select v-model="pushGoodsObj.salePriceDto.salePriceReference" style="width: 150px"
                       placeholder="请选择" :disabled="pushGoodsObj.salePriceChange != 1" size="small"
                       @change="changeSalePrice">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            &nbsp;
            <el-select v-model="pushGoodsObj.salePriceDto.salePriceAdjust" style="width: 150px"
                       placeholder="请选择" size="small" :disabled="pushGoodsObj.salePriceChange != 1">
              <el-option v-for="item in adjustOptions" :key="item.value" :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
            &nbsp;
            <el-input v-model="pushGoodsObj.salePriceDto.salePriceNum" placeholder="请输入内容" size="small"
                      style="width: 150px" :disabled="pushGoodsObj.salePriceChange != 1"
                      @input="inputPriceNum"></el-input>
            &nbsp;
            <el-select v-model="pushGoodsObj.salePriceDto.salePriceUnit" style="width: 100px" placeholder="请选择"
                       size="small" :disabled="pushGoodsObj.salePriceChange != 1" @change="changePriceUnit">
              <el-option v-for="item in unitOptions" :key="item.value" :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
            <br/>
            <el-radio-group v-model="pushGoodsObj.salePriceChange">
              <el-radio label="2">不改变</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <el-button type="primary" style="float: right; margin-right: 20px" @click="nextPush">下一步<i
          class="el-icon-arrow-right el-icon--right"></i></el-button>
    </ModalDialog>

    <!-- 推送目标 -->
    <ModalDialog title="选择具体推送目标" id="pushGrounp" ref="pushGrounp"
                 :width="pushGoodsObj.selectionObjects.length * 400 + 'px'">
      <div style="display: flex">
        <div style="width: 400px; flex: 1; height: 400px; overflow-y: scroll"
             v-if="pushGoodsObj.selectionObjects.includes('group')">
          <h3>分组</h3>
          <div>
            <el-tree ref="groupTree" :data="groupProps" node-key="id" show-checkbox>
            </el-tree>
          </div>
        </div>
        <div style="width: 400px; flex: 1; height: 400px; overflow-y: scroll"
             v-if="pushGoodsObj.selectionObjects.includes('shop')">
          <h3>商城</h3>
          <div>
            <el-tree ref="shopTree" :data="shopProps" node-key="id" show-checkbox>
            </el-tree>
          </div>
        </div>
        <div style="width: 400px; flex: 1; height: 400px; overflow-y: scroll"
             v-if="pushGoodsObj.selectionObjects.includes('topic')">
          <h3>活动</h3>
          <div>
            <el-tree ref="topicTree" :data="topicProps" node-key="id" show-checkbox>
            </el-tree>
          </div>
        </div>
      </div>
      <el-divider></el-divider>

      <el-button type="primary" style="float: right; margin-right: 20px" @click="submitPush">提交&nbsp;&nbsp;<i
          class="el-icon-arrow-right el-icon--right"></i></el-button>
      <el-button type="primary" style="float: right; margin-right: 20px" @click="$refs.pushGrounp.close()">上一步<i
          class="el-icon-arrow-left el-icon--left"></i></el-button>
    </ModalDialog>

    <!-- 导出工具 -->
    <ModalDialog id="exportTool" ref="exportTool" title="导出工具" style="padding-bottom: 0px !important;">
      <el-row>

        <el-col v-loading="excelLoading" :span="8" class="mg-r-20 " align="center" justify="center">
          <label class="fs-6 form-label fw-bold">商品导出</label>
          <el-tooltip class="item" content="根据商品列表页的筛选条件导出对应的商品数据" effect="dark"
                      placement="top-start">
            <i class='el-icon-info'/>
          </el-tooltip>
          <br>
          <img class="cursor-pointer bg-hover-secondary" src="/images/XLS.png" alt="点击导出数据"
               @click="exportData(1,1)"/>
        </el-col>

        <el-col v-loading="pptLoading" :span="8" class="mg-r-20 cursor-pointer " align="center" justify="center">
          <label class="fs-6 form-label fw-bold">方案导出</label><br>
          <img class="cursor-pointer bg-hover-secondary" src="/images/PPT.png" alt="点击导出数据"
               @click="exportData(2,1)"/>
        </el-col>

        <el-col v-loading="excelProgrammeLoading" :span="8" align="center" class="mg-r-20 cursor-pointer "
                justify="center">
          <label class="fs-6 form-label fw-bold">方案导出</label><br>
          <img alt="点击导出数据" class="cursor-pointer bg-hover-secondary" src="/images/XLS.png"
               @click="openExcelDialogVisible"/>
        </el-col>

        <!--<el-col :span="8" class="mg-r-20 cursor-pointer" align="center" justify="center">
          <label class="fs-6 form-label fw-bold">导出PDF</label><br>
          <img class="cursor-pointer bg-hover-secondary" src="/images/PDF.png" alt="点击导出数据"
               @click="exportData(3,1)"/>
        </el-col>-->

      </el-row>
      <div class="row fv-row mb-7 fv-plugins-icon-container"></div>
      <div style="margin-left: 330px !important;">
        <button type="button" class="btn btn-primary" @click="exportData(0,2)">
          关闭
        </button>
      </div>
    </ModalDialog>

    <!-- 导出方案选择 -->
    <ModalDialog id="pptExportProgramme" ref="pptExportProgramme" title="PPT商品导出方案" width="70%"
                 style="padding-bottom: 0px !important;">
      <el-form ref="pptGoodsData" :model="pptExportProgramme" label-width="100px" class="demo-ruleForm"
               label-position="top">
        <el-row>
          <el-col :span="10" style="margin-left: 50px !important;">
            <el-form-item label="PPT模板" prop="giftShowPrice" class="fw-bold">
              <el-button @click="openDraweAndQuery" type="primary" style="margin-left: 5px;">
                PPT模板
              </el-button>
              <div v-if="pptModule.pptImg" class="overlay col-xl-3" style="margin-left: 30px; margin-top: 10px;">
                <div class="overlay-wrapper"
                     style=" display: flex;justify-content: center;align-items: center;">
                  <img class="rounded w-200px h-200px"
                       :src="pptModule.pptImg" alt="礼包详情" style="max-width: 200px; max-height: 100px;">
                </div>
                <div class="overlay-layer bg-dark bg-opacity-5 rounded"
                     style="display: flex;flex-direction: column;">
                  <a href="#" class="btn btn-sm btn-primary btn-shadow"
                     @click="view(3)">查看大图</a>
                </div>
              </div>
              <el-drawer class="fw-bold" :append-to-body="true" :close-on-click-modal="false"
                         title="PPT模板列表展示" :visible.sync="drawer" :direction="direction" size="22%">
                <span slot="title">
                  PPT模板列表展示
                  <el-button class="me-5" size="small" style="float: right;" type="primary"
                             @click="changePPTModule">确定</el-button>
                </span>
                <!--<ul class="infinite-list" style="overflow:auto;width:100%;padding-left:1rem;">-->
                <li v-for="item in pptModules" :key="item.id" class="infinite-list-item imageListItem"
                    style="list-style:none;">
                  <el-radio v-model="pptModule" :label="item" style="display:flex;">
                    <el-row>
                      <label class="fs-5 fw-bold">{{ item.pptTitle }}</label><br>
                    </el-row>
                    <el-row>
                      <el-image :src="item.pptImg" alt="图片" lazy/>
                    </el-row>
                  </el-radio>
                </li>
                <!--</ul>-->
              </el-drawer>
            </el-form-item>
          </el-col>
          <el-col :span="10" style="margin-left: 50px !important;">
            <el-form-item label="商品布局" prop="goodsDisplayModel" class="fw-bold fs-10">
              <el-radio v-model="pptExportProgramme.goodsDisplayModel" :label="1">商品详情展示</el-radio>
              <!--              <el-radio v-model="pptExportProgramme.goodsDisplayModel" :label="2" >礼包详情展示</el-radio>-->
            </el-form-item>
            <div class="overlay col-xl-3" style="margin-left: 70px">
              <div class="overlay-wrapper"
                   style=" display: flex;justify-content: center;align-items: center;">
                <img v-if="pptExportProgramme.goodsDisplayModel == 1" class="rounded w-200px h-200px"
                     src="/images/Goods_Detail.png" alt="礼包详情" style="max-width: 200px; max-height: 100px;">
                <img v-if="pptExportProgramme.goodsDisplayModel == 2" class="rounded w-200px h-200px"
                     src="/images/Gift_Pack_Detail.png" alt="礼包详情" style="max-width: 200px; max-height: 100px;">
              </div>
              <div class="overlay-layer bg-dark bg-opacity-5 rounded"
                   style="display: flex;flex-direction: column;">
                <a href="#" class="btn btn-sm btn-primary btn-shadow"
                   @click="view(pptExportProgramme.goodsDisplayModel)">查看大图</a>
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col v-if="pptExportProgramme.goodsDisplayModel === 2" :span="2" style="margin-left: 50px !important;">
            <el-form-item label="商品方案" prop="goodsDisplayModel" class="fw-bold fs-10">
              <el-input v-model="pptExportProgramme.pptGoodsDto.groupBuyPrice" placeholder="请输入方案价格"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="18" style="margin-left: 50px !important;">
            <label class="fs-6 form-label fw-bold mt-3">商品列表</label><br>
            <div style="display: flex; align-items: center;">
              <div v-for="(item, index) in pptExportProgramme.pptGoodsDto[0].groupGoodsList[0].goodsList"
                   :key="index" class="imgItem">
                <el-image v-if="item.id && item.picUrl.indexOf('http') == 0" :src="item.picUrl" class="max-w-10px"
                          lazy></el-image>
                <el-image class="max-w-10px" v-else :src="`https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com${item.picUrl}`" lazy></el-image>
                <button v-if="pptExportProgramme.pptGoodsDto[0].groupGoodsList[0].goodsList.length > 0"
                        @click="removeImages(item, index)" class="removeImage bg-hover-danger" type="button">
                  <i class="el-icon-close "></i>
                </button>
              </div>
              <el-button type="primary" @click="exportData(4,1)">挑选商品</el-button>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="10" style="margin-left: 50px !important;">
            <el-form-item class="fw-bold" label="附加选项" prop="giftShowPrice">
              <el-checkbox v-model="pptExportProgramme.isEnableSupplPrice">启用分销价</el-checkbox>
              <el-checkbox v-model="pptExportProgramme.isEnableSalePrice">启用销售价</el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10" style="margin-left: 50px !important;">
            <el-form-item class="fw-bold" label="上传LOGO" prop="giftShowPrice">
              <div class="col-lg-8">
                <!--begin::Image input-->
                <div class="image-input image-input-outline blankPng" data-kt-image-input="true"
                     style="background-image: url(assets/media/avatars/blank.png)">
                  <!--begin::Preview existing avatar-->
                  <div :style="`background-image: url(${imageLOGOSrc})`"
                       class="image-input-wrapper w-106px h-31x"></div>
                  <!--end::Preview existing avatar-->
                  <!--begin::Label-->
                  <label
                      class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-bs-original-title="上传图像" data-bs-toggle="tooltip" data-kt-image-input-action="change"
                      title=""> <i class="bi bi-pencil-fill fs-7"></i>
                    <!--begin::Inputs--> <input id="pic_file_list" ref="avatarLOGOFile"
                                                accept=".png" name="avatar" type="file"
                                                @change="uploadPic('avatarLOGOFile')">
                    <input name="avatar_remove" type="hidden">
                    <input id="picLOGOUrl" name="picLOGOUrl" type="hidden" value="">
                  </label>
                  <!--end::Label-->
                  <!--begin::Cancel-->
                  <span
                      class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-bs-original-title="取消" data-bs-toggle="tooltip" data-kt-image-input-action="cancel"
                      title=""> <i class="bi bi-x fs-2"></i>
										</span>
                  <!--end::Cancel-->
                  <!--begin::Remove-->
                  <span
                      class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-bs-original-title="移除图像" data-bs-toggle="tooltip" data-kt-image-input-action="remove"
                      title=""> <i class="bi bi-x fs-2"></i>
										</span>
                  <!--end::Remove-->
                </div>
                <!--end::Image input-->
                <!--begin::Hint-->
                <div class="form-text">允许的文件类型: png
                  <br>
                  建议尺寸：106*31
                </div>
                <!--end::Hint-->
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="row fv-row mb-7 fv-plugins-icon-container"></div>
      <div style="display: flex; justify-content: flex-end;">
        <button type="button" class="btn btn-primary" @click="exportData(2,2)"
                style="margin-right: 20px !important;">
          关闭
        </button>
        <button type="button" class="btn btn-primary" @click="clickPPT">
          确定
        </button>
      </div>
    </ModalDialog>

    <el-dialog :visible.sync="templatePicUrlVisible" width="60%">
      <el-image style="width: 100%; height: 50%" :src="templatePicUrl"></el-image>
    </el-dialog>


    <ModalDialog
        ref="excelDialogVisible"
        title="Excel商品导出方案"
        width="70%">
      <el-row>
        <el-col :span="18" style="margin-left: 50px !important;">
          <label class="fs-6 form-label fw-bold mt-3">商品列表</label><br>
          <div style="display: flex; align-items: center;">
            <div v-for="(item, index) in pptExportProgramme.pptGoodsDto[0].groupGoodsList[0].goodsList"
                 :key="index" class="imgItem">
              <el-image v-if="item.id && item.picUrl.indexOf('http') == 0" :src="item.picUrl" class="max-w-10px"
                        lazy></el-image>
              <el-image v-else :src="`https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com${item.picUrl}`" class="max-w-10px" lazy></el-image>
              <button v-if="pptExportProgramme.pptGoodsDto[0].groupGoodsList[0].goodsList.length > 0"
                      class="removeImage bg-hover-danger" type="button" @click="removeImages(item, index)">
                <i class="el-icon-close "></i>
              </button>
            </div>
            <el-button type="primary" @click="exportData(4,1)">挑选商品</el-button>
          </div>
        </el-col>
      </el-row>
      <div style="margin-top: 30px">
        <el-button @click="$refs.excelDialogVisible.close()">取 消</el-button>
        <el-button type="primary" @click="submitExcelProgramme">确 定</el-button>
      </div>

    </ModalDialog>

    <!--挑选导出ppt的商品数据-->
    <ModalDialog ref="goodsListDialog" style="z-index: 999" title="请挑选商品到礼包"
                 width="95%">
      <el-row :gutter="20">
        <el-col :span="18">
          <div>
            <div style="width: 100%">
              <div class="card">
                <div class="card-body" style="padding: 5px 5px !important;">
                  <div class="d-flex mb-3">
                    <div class="col-lg-2 me-2">
                      <label class="fs-4 form-label fw-bold">商品池</label>
                      <br/>
                      <el-select v-model="paramss.type" placeholder="请选择" @change="shaixuan">
                        <el-option v-for="item in optionss" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                    <div class="col-lg-3" v-if="paramss.type==2">
                      <label class="fs-4 form-label fw-bold">商品来源</label>
                      <br/>
                      <el-select v-model="paramss.isJd" placeholder="请选择" @change="shaixuan">
                        <el-option v-if="!role.includes('vipMember')"
                                   :value="1"
                                   label="京东货源">
                        </el-option>
                        <el-option :label="role.includes('vipMember')?'会员商品':'厂供货源'"
                                   :value="2">
                        </el-option>

                      </el-select>
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="position-relative w-md-400px me-md-2">
                      <span
                          class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6">
                        <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px"
                             xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                            <rect height="24" width="24" x="0" y="0"></rect>
                            <path
                                d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                            <path
                                d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                fill="#000000" fill-rule="nonzero"></path>
                          </g>
                        </svg>
                      </span>
                      <input id="sTitle" v-model="paramss.title" class="form-control form-control-solid ps-10"
                             name="shopName" placeholder="商品名称" type="text" value=""/>
                    </div>
                    <div class="d-flex align-items-center">
                      <div class="btn btn-primary me-5 btn-sm" @click="shaixuan">
                        查询
                      </div>
                      <div class="btn btn-secondary me-5 btn-sm" @click="resetBtn">
                        重置
                      </div>
                      <a id="kt_horizontal_search_advanced_link" class="btn btn-link" data-bs-toggle="collapse"
                         href="#kt_advanced_search_form">高级查询</a>
                    </div>
                  </div>
                  <!--begin::高级搜索-->
                  <div id="kt_advanced_search_form" class="collapse">
                    <div class="separator separator-dashed mt-9 mb-6"></div>
                    <div class="row g-8 mb-8">
                      <div class="col-lg-3">
                        <label class="fs-6 form-label">一级分类</label>
                        <br/>
                        <el-select v-model="paramss.categoryId1" placeholder="请选择" @change="getCategory(1)">
                          <el-option v-for="item in categoryId1" :key="item.categoryId" :label="item.fullName"
                                     :value="item.categoryId">
                          </el-option>
                        </el-select>
                      </div>
                      <div class="col-lg-3">
                        <label class="fs-6 form-label">二级分类</label>
                        <br/>
                        <el-select v-model="paramss.categoryId2" placeholder="请选择" @change="getCategory(2)">
                          <el-option v-for="item in categoryId2" :key="item.categoryId" :label="item.fullName"
                                     :value="item.categoryId">
                          </el-option>
                        </el-select>
                      </div>

                      <div class="col-lg-3" v-if="paramss.type == 1">
                        <label class="fs-6 form-label fw-bolder text-dark"
                        >库存区间</label
                        >
                        <div class="row">
                          <div class="col-lg-6 fv-row fv-plugins-icon-container">
                            <input
                                type="text" id="minPoolSupplPrice" name="minPoolSupplPrice"
                                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                v-model="paramss.minStock" placeholder="最小值" value=""
                                oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                            />
                            <div
                                class="fv-plugins-message-container invalid-feedback"
                            ></div>
                          </div>
                          <div class="col-lg-6 fv-row fv-plugins-icon-container">
                            <input
                                type="text"
                                id="maxPoolSupplPrice"
                                name="maxPoolSupplPrice"
                                class="form-control form-control-lg form-control-solid"
                                v-model="paramss.maxStock"
                                oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                                placeholder="最大值"
                                value=""
                            />
                            <div
                                class="fv-plugins-message-container invalid-feedback"
                            ></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <label class="fs-6 form-label fw-bolder text-dark"
                        >分销价区间</label
                        >
                        <div class="row">
                          <div class="col-lg-6 fv-row fv-plugins-icon-container">
                            <input
                                v-if="paramss.type == 1"
                                type="text" id="minPoolSupplPrice" name="minPoolSupplPrice"
                                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                v-model="paramss.minSupplPrice" placeholder="最小值" value=""
                                oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                            />
                            <input
                                v-if="paramss.type == 2"
                                type="text" id="minPoolSupplPrice" name="minPoolSupplPrice"
                                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                v-model="paramss.minPoolSupplPrice" placeholder="最小值" value=""
                                oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                            />
                            <div
                                class="fv-plugins-message-container invalid-feedback"
                            ></div>
                          </div>
                          <div class="col-lg-6 fv-row fv-plugins-icon-container">
                            <input
                                value=""
                                type="text"
                                v-if="paramss.type == 1"
                                id="maxPoolSupplPrice"
                                name="maxPoolSupplPrice"
                                class="form-control form-control-lg form-control-solid"
                                v-model="paramss.maxSupplPrice"
                                oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                                placeholder="最大值"
                            />
                            <input
                                value=""
                                type="text"
                                v-if="paramss.type == 2"
                                id="maxPoolSupplPrice"
                                name="maxPoolSupplPrice"
                                class="form-control form-control-lg form-control-solid"
                                v-model="paramss.maxPoolSupplPrice"
                                oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                                placeholder="最大值"
                            />
                            <div
                                class="fv-plugins-message-container invalid-feedback"
                            ></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <label class="fs-6 form-label fw-bolder text-dark"
                        >利润率区间</label
                        >
                        <div class="row">
                          <div class="col-lg-6 fv-row fv-plugins-icon-container">
                            <input
                                v-if="paramss.type==1"
                                type="text"
                                id="minRightRate"
                                name="minRightRate"
                                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                v-model="paramss.minRightRate"
                                oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                                placeholder="最小值"
                                value=""
                            />
                            <input
                                v-if="paramss.type==2"
                                type="text"
                                id="minQuanRightRate"
                                name="minQuanRightRate"
                                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                v-model="paramss.minQuanRightRate"
                                oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                                placeholder="最小值"
                                value=""
                            />
                            <div
                                class="fv-plugins-message-container invalid-feedback"
                            ></div>
                          </div>
                          <div class="col-lg-6 fv-row fv-plugins-icon-container">
                            <input
                                v-if="paramss.type==1"
                                type="text"
                                id="maxRightRate"
                                name="maxRightRate"
                                class="form-control form-control-lg form-control-solid"
                                v-model="paramss.maxRightRate"
                                oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                                placeholder="最大值"
                                value=""
                            />
                            <input
                                v-else
                                type="text"
                                id="maxQuanRightRate"
                                name="maxQuanRightRate"
                                class="form-control form-control-lg form-control-solid"
                                v-model="paramss.maxQuanRightRate"
                                oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                                placeholder="最大值"
                                value=""
                            />
                            <div
                                class="fv-plugins-message-container invalid-feedback"
                            ></div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive" v-loading="loading" element-loading-text="拼命加载中"
               element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
            <table class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer">
              <thead>
              <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                <th class="min-w-300px" tabindex="0" aria-controls="kt_ecommerce_products_table" rowspan="1" colspan="1"
                    aria-label="Product: activate to sort column ascending" style="width: 212.141px">
                  商品信息
                </th>
                <th>
                  分类
                </th>
                <th class="text-center">
                  市场价
                </th>
                <th class="text-center">
                  分销价/供货价
                </th>
                <th class="text-center" v-if="paramss.type==1">
                  销售价
                </th>
                <th class="text-center">
                  利润率
                </th>
                <!--                <th class="text-center">
                                  运费模板
                                </th>-->
                <th class="text-center">
                  库存
                </th>
                <th class="text-center">
                  商品来源
                </th>
                <th aria-label="操作" class="text-center sorting_disabled" colspan="1" rowspan="1"
                    style="width: 100px;">
                  操作
                </th>
              </tr>
              </thead>
              <tbody class="fw-semibold text-gray-600">
              <tr v-for="(item, index) in selGoodsList" :class="{ old: index % 2 == 0, even: index % 2 != 0 }"
                  :key="index">
                <td>
                  <div class="d-flex align-items-center">
                    <a href="javascript:void(0);" class="symbol symbol-50px">
                      <img class="symbol-label lozad" v-if="item.picUrl.indexOf('http') == 0" :src="`${item.picUrl}`"/>
                      <img class="symbol-label lozad" v-else :src="`https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com${item.picUrl}`"/>
                    </a>
                    <div class="ms-5">
                      <a href="javascript:void(0);" class="text-gray-800 text-hover-primary fs-5"
                         data-kt-ecommerce-product-filter="product_name">{{ item.title }}</a>
                    </div>
                  </div>
                </td>
                <td>
                  <div v-html="item.categoryName"></div>
                </td>
                <td class="text-center">{{ item.price | format }}</td>
                <td class="text-center">{{ item.supplPrice | format }}</td>
                <td class="text-center" v-if="paramss.type==1">{{ item.salePrice | format }}</td>
                <td class="text-center" v-html="item.rightRate" v-if="paramss.type==1"></td>
                <td class="text-center" v-html="item.maxRightRate" v-else></td>
                <td class="text-center" v-if="paramss.type==1">{{ item.freightId }}</td>
                <!--                <td class="text-center" v-else-if="paramss.type==2 && item.isJD == 2">厂供模板</td>
                                <td class="text-center" v-else-if="paramss.type==2 && item.isJD == 1">京东模板</td>-->
                <td class="text-center" v-if="paramss.type==1">{{ item.stock || 0 }}</td>
                <td class="text-center" v-else-if="paramss.type==2 && item.isJD == 2">{{ item.stock || 0 }}</td>
                <td class="text-center" v-else-if="paramss.type==2 && item.isJD == 1">99</td>
                <td class="text-center" v-if="paramss.type==1">{{ item.type }}</td>
                <td class="text-center" v-else-if="paramss.type==2 && item.isJD == 1">京东</td>
                <td class="text-center" v-else-if="paramss.type==2 && item.isJD == 2">自有</td>
                <!-- 操作 -->
                <td class="text-center">
                  <el-link type="success" @click="addGroupGoods(item)" v-if="!idsArr.includes(item.id)">添加</el-link>
                </td>
              </tr>
              </tbody>
            </table>
            <Pagination :total-items="totalCount" @page-change="pageChange" ref="Pagination"></Pagination>
          </div>
        </el-col>
        <el-col :span="6">
          <div style="display: flex;justify-content:space-between;">
            <h4>已选商品<span style="font-size:12px;margin-left: 5px;">(共选择 {{ pptGoodsList.length }} 个商品)</span>
            </h4>
            <el-button v-if="pptGoodsList.length!=0" size="small" type="danger" @click="clearSelectedGoods">
              清空已选商品
            </el-button>
          </div>
          <div class="table-responsive">
            <table class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer">
              <thead>
              <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                <th class="min-w-200px" tabindex="0" aria-controls="kt_ecommerce_products_table" rowspan="1" colspan="1"
                    aria-label="Product: activate to sort column ascending" style="width: 212.141px">
                  商品信息
                </th>
                <th class="text-center sorting_disabled" rowspan="1" colspan="1" aria-label="操作" style="width: 80px">
                  操作
                </th>
              </tr>
              </thead>
              <tbody class="fw-semibold text-gray-600">
              <tr v-for="(item, index) in pptGoodsList" :key="index"
                  :class="{ old: index % 2 == 0, even: index % 2 != 0 }">
                <td>
                  <div class="d-flex align-items-center">
                    <a href="javascript:void(0);" class="symbol symbol-50px">
                      <img class="symbol-label lozad" v-if="item.picUrl.indexOf('http') == 0" :src="`${item.picUrl}`"/>
                      <img class="symbol-label lozad" v-else :src="`https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com${item.picUrl}`"/>
                    </a>
                    <div class="ms-5">
                      <a href="javascript:void(0);" class="text-gray-800 text-hover-primary fs-5"
                         data-kt-ecommerce-product-filter="product_name">{{ item.title }}</a>
                    </div>
                  </div>
                </td>

                <!-- 操作 -->
                <td class="text-end">
                  <el-link type="danger" @click="delGroupGoods(item)">删除</el-link>
                </td>
              </tr>
              </tbody>
            </table>

          </div>
        </el-col>
      </el-row>

    </ModalDialog>


  </div>
</template>

<script>

import OwnGoods from "@/views/Goods/components/GoodsManage/components/OwnGoods/OwnGoodsList.vue";
import SelectionGoods from "@/views/Goods/components/GoodsManage/components/SelectionedGoods/SelectionedGoodsList.vue";
import AddOrUpdateVisible from "./components/AddOrUpdateVisible/AddOrUpdateGoods.vue";
import {mixins} from "./mixins";

import {selectionGoodsApi, updateBatchFreightApi, updateBatchSevenNrrApi, updateIsShowApi,} from "@/api/goodsManageApi";
import {getFreightListApi} from "@/api/freightApi";
import {getGoodsGroupByStaffIdApi, getWxShopByStaffIdApi, getWxTopicByStaffIdApi} from "@/api/groupGoodsApi";
import Tinymce from "@/components/Tinymce/index.vue";


export default {
  mixins: [mixins],
  name: "goodsManage",
  components: {
    Tinymce,
    OwnGoods,
    SelectionGoods,
    AddOrUpdateVisible,
  },
  // 组件的props定义,用于子组件接收父组件传值
  props: {},
  // 组件的data属性
  data() {
    return {
      state: "",
      groupProps: [],
      shopProps: [],
      topicProps: [],
      unitOptions: [
        {
          value: "1",
          label: "%",
        },
        {
          value: "2",
          label: "元",
        },
      ],
      adjustOptions: [
        //{
        //  value: "1",
        //  label: "上调",
        //},
        {
          value: "2",
          label: "下降",
        },
      ],
      options: [
        //{
        //  value: "1",
        //  label: "销售价",
        //},
        {
          value: "2",
          label: "市场价",
        },
        {
          value: "3",
          label: "分销价",
        },
      ],
      salePriceReference: "1",
      checkboxInput: true,
      idArr: [],
      categoryOne: [],
      categoryTwo: [],
      loading: false,
      pushGoodsObj: {
        topicIds: [],
        shopIds: [],
        goodsGroupIds: [],
        selectionObjects: [],
        goodsIds: [],
        isJd: 1,
        salePriceChange: "2",
        supplPriceChange: "2",
        salePriceDto: {
          salePriceReference: "2",
          salePriceAdjust: "2",
          salePriceNum: "",
          salePriceUnit: "1",
        },
        supplPriceDto: {
          supplPriceReference: "2",
          supplPriceAdjust: "2",
          supplPriceNum: "",
          supplPriceUnit: "1",
        },
      },
      value1: 1,
      fVisible: false,
      sevenNrrVisible: false,
      goodsUrl: "",
      id: "",
      index: 1,
      rows: [],
      form: {
        freightId: "",
      },
      freightList: [],
      orderStatus: [
        {
          title: "自有商品",
          count: 0,
          icon: "ki-outline ki-bookmark-2 fs-5 me-2",
        },
        {
          title: "已上架商品",
          count: 0,
          icon: "ki-outline ki-note-2 fs-5 me-2",
        },
      ],
    };
  },
  // 组件的方法
  methods: {
    //子传父数据
    setArrId(idarr) {
      this.idArr = idarr;
    },
    async pushGood(id, state) {
      this.state = 1;
      this.id = id;
      this.$refs.pushGoodsAll.open();
      this.shopProps = [];
      this.topicProps = [];
      this.groupProps = [];
      let {data: res1} = await getWxTopicByStaffIdApi();
      res1.forEach((item, index) => {
        this.topicProps.push({
          id: item.id,
          label: item.title,
        });
      });
      let {data: res2} = await getWxShopByStaffIdApi();
      res2.forEach((item, index) => {
        this.shopProps.push({
          id: item.id,
          label: item.name,
        });
      });
      let {data: res3} = await getGoodsGroupByStaffIdApi();
      res3.forEach((item, index) => {
        this.groupProps.push({
          id: item.id,
          label: item.name,
        });
      });
    },
    async submitPush(id) {
      this.pushGoodsObj.topicIds = [];
      this.pushGoodsObj.shopIds = [];
      this.pushGoodsObj.goodsGroupIds = [];
      this.pushGoodsObj.goodsIds = [];
      if (this.pushGoodsObj.selectionObjects.includes("topic")) {
        this.pushGoodsObj.topicIds = this.$refs.topicTree.getCheckedKeys();
        if (this.pushGoodsObj.topicIds.length == 0) {
          let index = this.pushGoodsObj.selectionObjects.indexOf("topic");
          this.pushGoodsObj.selectionObjects.splice(index, 1);
        }
      }
      if (this.pushGoodsObj.selectionObjects.includes("shop")) {
        this.pushGoodsObj.shopIds = this.$refs.shopTree.getCheckedKeys();
        if (this.pushGoodsObj.shopIds.length == 0) {
          let index = this.pushGoodsObj.selectionObjects.indexOf("shop");
          this.pushGoodsObj.selectionObjects.splice(index, 1);
        }
      }
      if (this.pushGoodsObj.selectionObjects.includes("group")) {
        this.pushGoodsObj.goodsGroupIds = this.$refs.groupTree.getCheckedKeys();
        if (this.pushGoodsObj.goodsGroupIds.length == 0) {
          let index = this.pushGoodsObj.selectionObjects.indexOf("group");
          this.pushGoodsObj.selectionObjects.splice(index, 1);
        }
      }
      if (this.pushGoodsObj.supplPriceChange == "2") {
        this.pushGoodsObj.supplPriceDto = {
          supplPriceReference: "",
          supplPriceAdjust: "",
          supplPriceNum: "",
          supplPriceUnit: "",
        };
      }
      if (this.pushGoodsObj.salePriceChange == "2") {
        this.pushGoodsObj.salePriceDto = {
          salePriceReference: "",
          salePriceAdjust: "",
          salePriceNum: "",
          salePriceUnit: "",
        };
      }
      if (this.id == "") {
        this.pushGoodsObj.goodsIds = this.idArr;
      } else {
        this.pushGoodsObj.goodsIds = [this.id];
      }
      this.pushGoodsObj.isJd = 2;

      let arr = ["group", "shop", "topic"];
      let arr1 = [];
      arr.forEach((item) => {
        if (this.pushGoodsObj.selectionObjects.includes(item)) {
          arr1.push(item);
        }
      });

      let goodNum = this.pushGoodsObj.goodsIds.length;
      let {data} = await selectionGoodsApi(this.pushGoodsObj);
      // 不成功的数据
      let total = 0;
      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          total += data[key];
        }
      }
      if (total === 0) {
        this.$message({
          message: "全部商品推送成功",
          type: "success",
        });
      } else if (total >= goodNum) {
        this.$message({
          message: "商品全部推送失败，请检查价格比例或者商城已存在商品!",
          type: "error",
          offset: 110
        });
      } else if (total < goodNum) {
        this.$message.warning({
          message: "商品部分推送失败，请调整价格比例或部分商品已被推送!",
          offset: 110
        })
      }


      this.idArr = [];
      this.$refs.pushGoodsAll.close();
      this.$refs.pushGrounp.close();
    },
    async updatePrice(id, isJd) {
      this.$refs.goodInfoComponent.openGoodsInfo(id, isJd);
      this.$message({
        message: "更新成功",
        type: "success",
      });
    },
    async nextPush() {
      if (this.pushGoodsObj.selectionObjects.length == 0) {
        this.$message({
          message: "请选择推送目标",
          type: "warning",
        });
        return;
      }
      this.$refs.pushGrounp.open();
    },
    /* 商品推送 1 */
    async pushAll() {
      this.id = "";
      this.state = ""
      if (this.idArr.length == 0) {
        return;
      }
      this.$refs.pushGoodsAll.open();
      this.shopProps = [];
      this.topicProps = [];
      this.groupProps = [];
      let {data: res1} = await getWxTopicByStaffIdApi();
      res1.forEach((item, index) => {
        this.topicProps.push({
          id: item.id,
          label: item.title,
        });
      });
      let {data: res2} = await getWxShopByStaffIdApi();
      res2.forEach((item, index) => {
        this.shopProps.push({
          id: item.id,
          label: item.name,
        });
      });
      let {data: res3} = await getGoodsGroupByStaffIdApi();
      res3.forEach((item, index) => {
        this.groupProps.push({
          id: item.id,
          label: item.name,
        });
      });
    },
    addIdArr(id) {
      if (this.idArr.includes(id)) {
        let index = this.idArr.indexOf(id);
        if (index !== -1) {
          this.idArr.splice(index, 1);
        }
      } else {
        this.idArr.push(id);
      }
      let a = true;
      this.rows.forEach((e) => {
        if (!this.idArr.includes(e.id)) {
          a = false;
        }
      });
      this.checkboxInput = a;
    },
    async batchOperations(command) {
      this.idArr = this.$refs.OwnGoods.idArr;
      if (this.idArr.length == 0) {
        this.sevenNrrVisible = false;
        this.$swal.fire({
          icon: "error",
          title: "请选中一个及以上的商品!",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });
        return;
      }
      if (command == "sevenNrr") {
        this.sevenNrrVisible = true;
      }
      if (command == "freight") {
        this.fVisible = true;
        let {data} = await getFreightListApi({
          size: 200,
          current: 1,
        });
        this.freightList = data.rows;
        const ownGoodsComponent = this.$refs.OwnGoods; // 通过ref获取组件实例
        if (ownGoodsComponent && ownGoodsComponent.getSjGoodsList) {
          // 调用子组件方法
          await ownGoodsComponent.getSjGoodsList();
        }
      }
      if (command == "push") {
        this.pushAll();
      }
      console.log(this.idArr)

      if (command == "listing") {
        this.batchUpdateStatus(1);
      }
      if (command == "removeFromShelves") {
        this.batchUpdateStatus(2);
      }
      if (command == "delete") {
        this.batchUpdateStatus(3);
      }
    },
    async batchUpdateStatus(isShow) {

      console.log(this.idsArr)
      let msg = isShow == 1 ? "上架" : isShow == 2 ? "下架" : isShow == 3 ? "删除" : "未知状态";
      this.$confirm(`你确定${msg}${this.idArr.length}件商品 `, `是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true,
        type: "warning",
      }).then(async () => {
        await updateIsShowApi(
            JSON.stringify({
              ids: this.idArr,
              isShow: isShow,
            })
        );
        this.$message({
          type: "success",
          message: msg + "成功!",
        });
        this.$refs['OwnGoods'].getSjGoodsList();
      }).catch(() => {
      });
    },
    async batchFreight() {
      let {data} = await updateBatchFreightApi(
          JSON.stringify({
            ids: this.idArr,
            freightId: this.form.freightId,
          })
      );
      this.$swal.fire({
        icon: "success",
        title: "操作成功!",
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 2000,
      });
      const ownGoodsComponent = this.$refs.OwnGoods; // 通过ref获取组件实例
      if (ownGoodsComponent && ownGoodsComponent.getSjGoodsList) {
        // 调用子组件方法
        await ownGoodsComponent.getSjGoodsList();
      }
      this.fVisible = false;
      this.$refs.OwnGoods.idArr = [];
      this.$refs.OwnGoods.checkboxInput = false;
      this.idArr = [];
    },
    async batchSevenNrr() {
      let {data} = await updateBatchSevenNrrApi(
          JSON.stringify({
            ids: this.idArr,
            sevenNRR: this.value1,
          })
      );
      this.$swal.fire({
        icon: "success",
        title: "操作成功!",
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 2000,
      });
      const ownGoodsComponent = this.$refs.OwnGoods; // 通过ref获取组件实例
      if (ownGoodsComponent && ownGoodsComponent.getSjGoodsList) {
        // 调用子组件方法
        await ownGoodsComponent.getSjGoodsList();
      }
      this.sevenNrrVisible = false;
      this.$refs.OwnGoods.idArr = [];
      this.$refs.OwnGoods.checkboxInput = false;
      this.idArr = [];
    },
    handleCommand(a) {
      this.$router.push(a);
    },
    //上架小助手
    assistant() {
      if (this.goodsUrl == "") {
        this.$swal.fire({
          icon: "error",
          title: "请输入京东商品链接!",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });
        return;
      }
      let url = encodeURIComponent(this.goodsUrl);
      this.$router.push({
        name: "AddOrUpdateVisible",
        params: {id: url},
      });
    },

    /*exp() {
      if (this.index) {
      // 导出数据
      this.$refs.OwnGoods.exp();
      } else {
      }
    },*/

    // 将商品id传给子组件
    gotoGoods(id) {
      if (id) {
        this.id = id;
      } else {
        this.id = "";
      }
    },
    // 选择推送目标改变时触发
    changeSelectionObjects() {
      if (this.pushGoodsObj.selectionObjects == 'group') {
        this.pushGoodsObj.salePriceChange = '2'
        this.pushGoodsObj.salePriceDto.salePriceNum = null
      }
    },
    // 按照价格改变时 调整方式需要改变
    changeSalePrice() {
      if (this.pushGoodsObj.salePriceDto.salePriceReference == 2) {
        this.pushGoodsObj.salePriceDto.salePriceAdjust = "2";
        this.adjustOptions = [
          {
            value: "2",
            label: "下降",
          },
        ]
      } else {
        this.adjustOptions = [
          {
            value: "1",
            label: "上调",
          },
          {
            value: "2",
            label: "下降",
          },
        ]
      }
    },
    // 输入价格时校验数据
    inputPriceNum() {
      let value = parseFloat(this.pushGoodsObj.salePriceDto.salePriceNum);
      if (this.pushGoodsObj.salePriceDto.salePriceUnit == "1" && (isNaN(value) || value < 0 || value > 99.99)) {
        this.$message.error("请输入正确的比例! 0~99.99")
        this.pushGoodsObj.salePriceDto.salePriceNum = null;
      } else if (this.pushGoodsObj.salePriceDto.salePriceUnit == "2" && (isNaN(value) || value < 0)) {
        this.$message.error("请输入正数!")
        this.pushGoodsObj.salePriceDto.salePriceNum = null;
      }
    },
    // 改变加价方式(单位) 时 将加价 价格 置为空
    changePriceUnit() {
      this.pushGoodsObj.salePriceDto.salePriceNum = null;
    },
    // 将多选改成单选
    setTree(data, typeName) {
      this.$refs[typeName].setCheckedKeys([]);
      if (data) {
        this.$refs[typeName].setCheckedNodes([data]);
      }
    },
    groupTreeNodeClick(data) {
      this.setTree(data, "groupTree")
    },
    shopTreeNodeClick(data) {
      this.setTree(data, "shopTree")
    },
    topicTreeNodeClick(data) {
      this.setTree(data, "topicTree")
    },
    canUseExportGoods2Other() {
      return this.role.some(item => item === 'firstGradeSupplier' || item === 'vipMember');
    },
    templateManagementCommand(command) {
      console.log(command)
      switch (command) {
        case 'freightTemplate':
          this.$router.push({path: 'goods/freight'})
          break
        case 'doorToDoorDelivery':
          this.$router.push({path: 'goods/delivery'})
          break
        case 'doorToDoorInstallation':
          this.$router.push({path: 'goods/install'})
          break
        default:
          break
      }
    }

  },
  // 在created钩子中执行的操作
  created() {
  },
};
</script>


<!-- 组件的样式 -->
<style>
.imageListItem {
  box-shadow: rgba(54, 54, 54, 0.4) 0px 0px 10px;
  background: #fff;
  margin: 20px;
  padding: 15px;
  padding-right: 0;
  box-sizing: border-box;
  border-radius: 10px;
}

.imageListItem img {
  width: 100% !important;
  aspect-ratio: 601 / 335;
  border-radius: 6px;
  margin-top: 10px;
  margin-left: -20px;
}

.el-drawer__header {
  margin-bottom: 0px !important;
  padding-bottom: 12px !important;
}

.content_box {
  background: #ffffff;
  padding: 20px;
}

.imgItem {
  width: 10%;
  aspect-ratio: 1/1;
  padding: 1px;
  //margin-top: 24px; position: relative; margin-right: 10px;
}


.removeImage {
  position: absolute;
  right: 1px;
  top: 10px;
  width: 25px;
  height: 25px;
  background: #ffffffc9;
  border-radius: 50%;
  border: 0;
  text-align: center;
  line-height: 25px;
}
</style>
