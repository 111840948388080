import {removeFileApi, uploadFileApi} from "@/api/uploadFileApi";
import {getCategoryList, getValueListApi} from "@/api/categoryApi";
import {getFreightSelectApi} from "@/api/freightApi";
import {getDeliveryListApi} from "@/api/deliveryApi";
import {getInstallSelectApi} from "@/api/install";
import {deliveryTime} from "@/utils/goodsUtil";
import {
    echoShareCodeApi,
    getGoodsAssistantApi,
    getGoodsInfoApi,
    getTaxListApi,
    getYiCaiCategoryApi,
    saveSjGoodsApi,
} from "@/api/goodsManageApi";
import Tinymce from "@/components/Tinymce/index.vue";
import {getBrandListApi} from "@/api/brandApi";
import {mapState} from "vuex";
import {Message} from "element-ui";

export const addOrUpdateGoods = {
    props: {},
    data() {
        return {
            // permissionList:[],
            echoAfter: true,
            brandDialogFormVisible: false,
            isStartNum: 1,
            deliveryTime,
            imgList: [],
            categoryOne: [],
            categoryTwo: [],
            categoryThree: [],
            deliveryList: [],
            freight: [],
            installList: [],
            isJdUrl: "1",
            goodsUrl: "",
            raxDialogFormVisible: false,
            // 商品信息
            goodsInfo: {
                taxCode: "",
                taxName: "",
                taxRate: "",
                invoiceType: "",
                unit: "",
                id: "",
                spuId: "",
                title: "",
                spuTitle: "",
                categoryId1: "",
                categoryId2: "",
                categoryId3: "",
                categoryId1Name: "",
                categoryId2Name: "",
                categoryId3Name: "",
                detail: "",
                specialSpec: "",
                price: "",
                supplPrice: "",
                salePrice: "",
                jdUrl: "",
                comparePrice: "1",
                buyType: "1",
                startNum: "1",
                isInstall: "",
                isDelivery: "",
                deliveryId: "",
                freightId: "",
                stock: "",
                weight: "",
                picUrl: "",
                sevenNRR: "1",
                isShow: "",
                isTwoShow: "",
                isQuan: "",
                saleAttrs: [],
                sjGoodsFile: [],
                deliveryTime: "24小时内发货",
                type: "",
                ratio: "",
                isVipGoods: false,
                brandName: "",
                brandId: "",
                cnCode: "",
                isJdLogistics: false,
                isJdLogisticsPrice: 0,
                isSfLogistics: false,
                isSfLogisticsPrice: 0,
            },
            valueSize: 0,
            valueList: [],
            arr: [],
            specialSpec: null,
            specialSpecObj: {},
            specialSpecArr: [],
            status: 0,
            goodsStatus: "",
            role: [],
            brandList: [],
            loading: false,
            brandObj: {
                brandId: "",
                brandName: "",
            },
            taxObj: {
                codeNum: "",
                generalTax: "",
                id: "",
                remark: null,
                serviceAbbName: "",
                serviceName: "",
                smallTax: "",
                smallTaxCode: "",
                taxExplan: "",
                taxId: ""
            },
            taxList: [],
            taxOptions: []
        };
    },
    methods: {
        selectTaxItem() {
            this.goodsInfo.taxCode = this.taxObj.codeNum
            this.goodsInfo.taxName = this.taxObj.serviceName
            if (this.taxObj.smallTax) {
                this.goodsInfo.taxRate = this.taxObj.smallTax.replace("%", "")
            }
            this.raxDialogFormVisible = false;
        },
        selectTax() {
            this.raxDialogFormVisible = true;
            this.getTaxList();
        },
        invoiceTypeChange(e) {

        },
        changeBrand() {
            this.brandList.forEach(r => {
                if (r.brandId == this.goodsInfo.brandId) {
                    this.brandObj = r;
                }
            })

        },
        async getTaxList(e) {
            this.taxList = []
            let {data} = await getTaxListApi();
            this.taxList = data.rows
            this.taxOptions = data.rows.slice(0, 20)

        },
        changeTax(item) {
            this.taxList.forEach(r => {
                if (r.codeNum == item) {
                    if (r.smallTax) {
                        r.smallTax = r.smallTax.replace("%", "")
                    }
                    this.taxObj = r;
                }
            })
        },
        remoteTaxMethod(e) {
            if (e !== '') {
                this.loading = true;
                this.taxOptions = this.taxList.filter(r => {
                    if (r.serviceName.indexOf(e) !== -1) {
                        return true;
                    }
                    return r.codeNum.indexOf(e) !== -1;
                })
                this.loading = false;
            } else {
                this.taxOptions = [];
            }
        },
        remoteMethod(e) {
            if (e !== '') {
                this.loading = true;
                this.getBrandList(e)
                this.loading = false;
            } else {
                this.options = [];
            }
        },
        selectBrandItem() {
            this.brandDialogFormVisible = false;
            if (this.brandObj.brandName) {
                this.goodsInfo.brandName = this.brandObj.brandName;
                this.goodsInfo.brandId = this.brandObj.brandId;
            }


        },
        selectBrand() {
            this.brandDialogFormVisible = true
            this.getBrandList("", this.goodsInfo.brandId, true)
        },
        checkPay() {
        },
        async getBrandList(brandName = "", brandId = "", a) {
            let {data} = await getBrandListApi({
                size: 10,
                current: 1,
                brandName,
                brandId,
            })
            this.brandList = data.rows
        },
        //移除图片
        async removeImages(item, index) {
            this.imgList.splice(index, 1);
            if (this.goodsInfo.spuId != "" && this.goodsInfo.id == "") {

            } else {
                await removeFileApi({
                    url: item,
                });
            }
            // if ((this.goodsInfo.spuId =="" && this.goodsInfo.id =="") || (this.goodsInfo.spuId !="" && this.goodsInfo.id !="") ) {

            // }
        },
        // 移除销售规格
        removeValue(index) {
            this.arr.splice(index, 1);
        },
        //保存
        async saveGoodsInfo(e) {
            if (e === 1) {
                this.goodsInfo.id = null;
            }

            if (this.brandObj.brandName) {
                this.goodsInfo.brandName = this.brandObj.brandName;
                this.goodsInfo.brandId = this.brandObj.brandId;
            }

            this.goodsInfo.sjGoodsFile = [];
            let obj = {};
            if (this.specialSpecArr) {
                this.specialSpecArr.forEach((e, index) => {
                    if (e.name) {
                        obj[e.key] = e.name;
                    } else {
                        delete obj[e.key];
                    }
                });
                this.goodsInfo.specialSpec = JSON.stringify(obj);
            } else {
                this.goodsInfo.specialSpec = null;
            }

            this.goodsInfo.saleAttrs = this.arr;

            let categoryId1Name = this.$refs.category1Name;
            let categoryId1Index = categoryId1Name.selectedIndex;
            if (this.goodsInfo.categoryId3Name == '没有查到'){
                Message.error('请重新选择分类')
                return;
            }
            if (this.goodsInfo.categoryId1) {
                this.goodsInfo.categoryId1Name =
                    categoryId1Name.options[categoryId1Index].text;
            } else {
                this.goodsInfo.categoryId1Name = "";
                this.goodsInfo.categoryId1 = "";
            }

            let categoryId2Name = this.$refs.category2Name;
            let categoryId2Index = categoryId2Name.selectedIndex;
            if (this.goodsInfo.categoryId2) {
                this.goodsInfo.categoryId2Name =
                    categoryId2Name.options[categoryId2Index].text;
            } else {
                this.goodsInfo.categoryId2Name = "";
                this.goodsInfo.categoryId2 = "";
            }

            let categoryId3Name = this.$refs.category3Name;
            let categoryId3Index = categoryId3Name.selectedIndex;
            if (this.categoryThree.length > 0) {
                this.goodsInfo.categoryId3Name =
                    categoryId3Name.options[categoryId3Index].text;
            } else {
                this.goodsInfo.categoryId3Name = "";
                this.goodsInfo.categoryId3 = "";
            }
            if (this.goodsInfo.buyType == 1) {
                this.goodsInfo.startNum = 1;
            }
            // 轮播图
            this.imgList.forEach((item, index) => {
                this.goodsInfo.sjGoodsFile.push({name: index, url: item});
            });
            if (this.goodsStatus == 1) {
                this.goodsInfo.id = "";
            }
            if (this.isStartNum == 1) {
                this.goodsInfo.startNum = 1
            }
            // 几级运营商
            this.goodsInfo.isVipGoods = false
            if ((this.role.includes('firstGradeSupplier') || this.role.includes('specialFirstGrade'))) {
                //this.goodsInfo.isShow = this.goodsInfo.isShow;
                this.goodsInfo.isTwoShow = 1;
            } else if (this.role.includes('secondTierSuppliers')) {
                this.goodsInfo.isShow = 2;
                //this.goodsInfo.isTwoShow = this.goodsInfo.isShow;
            } else if (this.role.includes('vipMember')) {
                this.goodsInfo.isVipGoods = true
            }
            if (!this.ruleForm()) {
                return;
            }
            if (this.goodsInfo.specialSpec == '{}') {
                this.goodsInfo.specialSpec = null;
            }
            if (this.goodsInfo.specialSpec && !this.goodsInfo.specialSpec.startsWith('{')) {
                this.goodsInfo.specialSpec = '{' + this.goodsInfo.specialSpec;
            }
            if (this.goodsInfo.specialSpec && !this.goodsInfo.specialSpec.endsWith('}')) {
                this.goodsInfo.specialSpec = this.goodsInfo.specialSpec + '}';
            }
            if (this.goodsInfo.isJdSfLogistics === "" || this.goodsInfo.isJdSfLogistics === false) {
                this.goodsInfo.isJdSfLogistics = false;
                this.goodsInfo.isJdSfLogisticsPrice = 0;
            }
            if (this.goodsInfo.isSfLogistics === "" || this.goodsInfo.isSfLogistics === false) {
                this.goodsInfo.isSfLogistics = false;
                this.goodsInfo.isSfLogisticsPrice = 0;
            }
            if (this.goodsInfo.isJdLogistics === "" || this.goodsInfo.isJdLogistics === false) {
                this.goodsInfo.isJdLogistics = false;
                this.goodsInfo.isJdLogisticsPrice = 0;
            }
            try {
                let {data} = await saveSjGoodsApi(this.goodsInfo);
                this.goodsInfo.spuId = data.spuId;
                this.$swal.fire({
                    icon: "success",
                    title: "保存成功",
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 2000,
                });
                if (e == 0) {
                    this.$router.push("/goods");
                }
            } catch (e) {
                if (e.code === 700721) {
                    this.$confirm('剩余可加入商会商品池个数不足，是否增购？（10元/个）', '提示', {
                        confirmButtonText: '增购',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        localStorage.setItem('payUrl', 'goods')
                        this.$refs.VipPayComponent.openPay({
                            type: 4,
                            rerunUrl: process.env.NODE_ENV === "development" ? "http://localhost:8080/#/payCallback" : "https://saas.zhelidao.com/#/payCallback"
                        })
                    }).catch(() => {

                    });
                }
            }
        },
        // 表单规则
        ruleForm() {
            //检查主图
            if (!this.goodsInfo.picUrl) {
                this.$swal.fire({
                    icon: "warning",
                    title: "请上传商品主图",
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 2000,
                });
                return false;
            }
            //检查市场价
            if (!this.goodsInfo.price) {
                this.$swal.fire({
                    icon: "warning",
                    title: "请填写商品市场价",
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 2000,
                });
                return false;
            }
            //检查分销价
            if (!this.goodsInfo.supplPrice) {
                this.$swal.fire({
                    icon: "warning",
                    title: "请填写商品分销价",
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 2000,
                });
                return false;
            }
            if (parseFloat(this.goodsInfo.supplPrice) > parseFloat(this.goodsInfo.price)) {
                this.$swal.fire({
                    icon: "warning",
                    title: "分销价不能大于市场价",
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 2000,
                });
                return false;
            }

            if (parseFloat(this.goodsInfo.salePrice) > parseFloat(this.goodsInfo.price)) {
                this.$swal.fire({
                    icon: "warning",
                    title: "销售价不能大于市场价",
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 2000,
                });
                return false;
            }

            if ((this.role.includes('firstGradeSupplier') || this.role.includes('specialFirstGrade')) && parseFloat(this.goodsInfo.salePrice) < parseFloat(this.goodsInfo.supplPrice)) {
                this.$swal.fire({
                    icon: "warning",
                    title: "销售价不可低于分销价",
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 2000,
                });
                return false;
            }

            // 检验分销价加价比例
            if ((this.role.includes('firstGradeSupplier') || this.role.includes('specialFirstGrade')) && !this.goodsInfo.ratio && this.goodsInfo.type == 2) {
                this.$swal.fire({
                    icon: "warning",
                    title: "请填写商品分销价加价比例",
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 2000,
                });
                return false;
            }
            //检查销售价
            if ((this.role.includes('firstGradeSupplier') || this.role.includes('specialFirstGrade')) && !this.goodsInfo.salePrice) {
                this.$swal.fire({
                    icon: "warning",
                    title: "请填写商品销售价",
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 2000,
                });
                return false;
            }
            //检查一级分类
            if (!this.goodsInfo.categoryId1) {
                this.$swal.fire({
                    icon: "warning",
                    title: "请选择一级分类",
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 2000,
                });
                return false;
            }
            //检查二级分类
            if (!this.goodsInfo.categoryId2) {
                this.$swal.fire({
                    icon: "warning",
                    title: "请选择二级分类",
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 2000,
                });
                return false;
            }
            //检查当存在三级分类是判断是否选择三级分类
            if (this.categoryThree.length != 0 && this.goodsInfo.categoryId3 == "") {
                this.$swal.fire({
                    icon: "warning",
                    title: "请选择三级分类",
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 2000,
                });
                return false;
            }
            //检查商品标题
            if (!this.goodsInfo.title) {
                this.$swal.fire({
                    icon: "warning",
                    title: "请填写商品标题",
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 2000,
                });
                return false;
            }
            //检查库存
            if (!this.goodsInfo.stock) {
                this.$swal.fire({
                    icon: "warning",
                    title: "请填写商品库存",
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 2000,
                });
                return false;
            } else {
                if (!(/^-?\d+$/.test(this.goodsInfo.stock)) || this.goodsInfo.stock <= 0) {
                    this.$message.warning("库存请输入正整数");
                    return
                }
            }
            //检查运费模板
            if (!this.goodsInfo.freightId) {
                this.$swal.fire({
                    icon: "warning",
                    title: "请选择运费模板",
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 2000,
                });
                return false;
            }
            //检查送货上门
            if (!this.goodsInfo.isDelivery) {
                this.$swal.fire({
                    icon: "warning",
                    title: "请选择是否送货上门",
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 2000,
                });
                return false;
            }
            //检查送货上门模板
            if (this.goodsInfo.isDelivery == "1" && this.goodsInfo.deliveryId == "") {
                this.$swal.fire({
                    icon: "warning",
                    title: "请选择送货上门模板",
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 2000,
                });
                return false;
            }

            // goodsInfo.deliveryId
            //检查是否有上门安装
            if (!this.goodsInfo.isInstall) {
                this.$swal.fire({
                    icon: "warning",
                    title: "请选择是否有上门安装",
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 2000,
                });
                return false;
            }
            //检查有上门安装模板
            if (this.goodsInfo.isInstall == "1" && this.goodsInfo.installId == "") {
                this.$swal.fire({
                    icon: "warning",
                    title: "请选择送货上门模板",
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 2000,
                });
                return false;
            }
            //检查是否支持比价
            if (this.isJdUrl == 1 && this.goodsInfo.jdUrl == "") {
                this.$swal.fire({
                    icon: "warning",
                    title: "请填写比价链接",
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 2000,
                });
                return false;
            }
            //检查商品详情
            if (!this.goodsInfo.detail) {
                this.$swal.fire({
                    icon: "warning",
                    title: "请填写商品详情",
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 2000,
                });
                return false;
            }
            //检查轮播图
            if (!this.imgList.length) {
                this.$swal.fire({
                    icon: "warning",
                    title: "请上传轮播图",
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 2000,
                });
                return false;
            }
            //检查销售方式
            if (this.isStartNum == 2 && this.goodsInfo.startNum == "") {
                this.$swal.fire({
                    icon: "warning",
                    title: "请填写集采数量",
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 2000,
                });
                return false;
            }
            //检查销售方式
            if (this.goodsInfo.sevenNRR == '' && this.goodsInfo.sevenNRR == null && !this.goodsInfo.sevenNRR) {
                this.$swal.fire({
                    icon: "warning",
                    title: "请填写集采数量",
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 2000,
                });
                return false;
            }
            if (this.goodsInfo.deliveryTime == '' && this.goodsInfo.deliveryTime == null && !this.goodsInfo.deliveryTime) {
                this.$swal.fire({
                    icon: "warning",
                    title: "请填写集采数量",
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 2000,
                });
                return false;
            }

            if (this.goodsInfo.isSfLogisticsPrice !== "" && this.goodsInfo.isSfLogistics === true) {
                if (!this.isFloat(this.goodsInfo.isSfLogisticsPrice)) {
                    this.$swal.fire({
                        icon: "warning",
                        title: "请填写顺丰快递运费",
                        toast: true,
                        position: "center",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    return false;
                }

            }

            if (this.goodsInfo.isJdLogisticsPrice !== "" && this.goodsInfo.isJdLogistics === true) {
                if (!this.isFloat(this.goodsInfo.isJdLogisticsPrice)) {
                    this.$swal.fire({
                        icon: "warning",
                        title: "请填写京东快递运费",
                        toast: true,
                        position: "center",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    return false;
                }
            }
            if (this.permissionList.includes('goods:weight:required')) {
                if (this.goodsInfo.weight.trim() === "") {
                    this.$swal.fire({
                        icon: "warning",
                        title: "请填写商品重量",
                        toast: true,
                        position: "center",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    return false;
                }
            }

            if (this.permissionList.includes('goods:unit:required')) {
                if (this.goodsInfo.unit == null || this.goodsInfo.unit.trim() === "") {
                    this.$swal.fire({
                        icon: "warning",
                        title: "请填写商品单位",
                        toast: true,
                        position: "center",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    return false;
                }
            }
            if (this.permissionList.includes('goods:jdurl:required')) {
                if (this.goodsInfo.jdUrl == null || this.goodsInfo.jdUrl.trim() === "") {
                    this.$swal.fire({
                        icon: "warning",
                        title: "请填写商品比价链接",
                        toast: true,
                        position: "center",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    return false;
                }
            }
            if (this.permissionList.includes('goods:brand:required')) {
                if (this.goodsInfo.brandName == null || this.goodsInfo.brandName.trim() === "") {
                    this.$swal.fire({
                        icon: "warning",
                        title: "请选择商品品牌",
                        toast: true,
                        position: "center",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    return false;
                }
            }
            if (this.permissionList.includes('goods:tax:code:required')) {
                if (this.goodsInfo.taxCode == null || this.goodsInfo.taxCode.trim() === "") {
                    this.$swal.fire({
                        icon: "warning",
                        title: "请选择商品税收编码",
                        toast: true,
                        position: "center",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    return false;
                }
            }
            if (this.permissionList.includes('goods:cn:code:required')) {
                if (this.goodsInfo.cnCode == null || this.goodsInfo.cnCode.trim() === "") {
                    this.$swal.fire({
                        icon: "warning",
                        title: "请输入商品69码",
                        toast: true,
                        position: "center",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    return false;
                }
            }

            return true;
        },
        // saveGoodsInfo() {},
        uploadSuccess(res, fileList) {
            this.imgList.push(res.data.fileUrl);
        },
        isFloat(str) {
            const regex = /^-?\d+(\.\d+)?$|^(-?\d+\.?\d*)$|^(-?\.\d+)$/;
            return regex.test(str);
        },
        //上传文件
        async uploadFileAvatar(event) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append("file", file);
            formData.append("moduleName", "goodsAvatar");
            let {data} = await uploadFileApi(formData);
            this.goodsInfo.picUrl = data.fileUrl;
        },
        //初始化分类
        async initCategory(level, item) {
            let {data} = await getCategoryList({
                parentId: item || 0,
            });
            if (level == '' || level == null) {
                this.categoryOne = data.list;
            }
            if (level === 2) {
                this.categoryTwo = data.list;
            }
            if (level === 3) {
                this.categoryThree = data.list;
            }
        },
        //移除主图
        romverAvatar() {
            this.goodsInfo.picUrl = "";
        },
        // 获取分类
        async findCategory(index) {
            if (index == 1) {

                if (this.goodsInfo.categoryId1 != "" && this.goodsInfo.categoryId1 != null) {
                    this.categoryTwo = [];
                    this.categoryThree = [];
                    this.goodsInfo.categoryId2 = "";
                    this.goodsInfo.categoryId3 = "";
                    let {data} = await getCategoryList({
                        parentId: this.goodsInfo.categoryId1,
                    });
                    this.categoryTwo = data.list;
                } else {
                    this.initCategory();
                }
            }

            if (index == 2) {

                if (this.goodsInfo.categoryId2 != "" && this.goodsInfo.categoryId2 != null) {
                    this.categoryThree = [];
                    this.goodsInfo.categoryId3 = "";
                    let {data} = await getCategoryList({
                        parentId: this.goodsInfo.categoryId2,
                    });
                    this.categoryThree = data.list;
                    if (this.categoryThree.length == 0) {
                        this.getValueList();
                    }
                }
            }
            if (index == 3) {
                if (!this.goodsInfo.categoryId3 || this.goodsInfo.categoryId3Name == "没有查到") {
                    this.goodsInfo.categoryId3 = "";
                    this.goodsInfo.categoryId3Name = "";
                }
                this.categoryThree.forEach(r => {
                    if (r.categoryId === this.goodsInfo.categoryId3) {
                        getYiCaiCategoryApi({
                            name: r.fullName
                        }).then(item => {
                            if (r.id !== null) {
                                this.goodsInfo.taxRate = +item.data.rate
                                this.goodsInfo.taxName = item.data.name
                                this.goodsInfo.taxCode = item.data.code.replace(/(0+)$/g, "")
                                console.log(this.goodsInfo)
                            }
                        })
                    }
                })
                this.getValueList();
            }
        },
        //获取运费模板
        async getFreightSelect() {
            let {
                data: {area},
            } = await getFreightSelectApi();
            this.freight = area;
        },
        // 获取送货上门
        async getDeliveryList() {
            let {data} = await getDeliveryListApi();
            this.deliveryList = data.area;
        },
        // 获取上门安装
        async getInstallSelect() {
            let {data} = await getInstallSelectApi();
            this.installList = data.area;
        },
        //获取商品规格
        async getValueList(index) {
            if (!this.goodsInfo.categoryId3) {
                return;
            }
            let categoryId3;
            if (this.categoryThree.length == 0) {
                categoryId3 = this.goodsInfo.categoryId2;
            } else {
                categoryId3 = this.goodsInfo.categoryId3;
            }

            let {data} = await getValueListApi({
                attrType: 1,
                categoryId3,
            });
            let arr = [];
            data.forEach((element) => {
                arr.push({
                    key: element.key,
                    value: eval(element.value),
                    name: "",
                });
            });
            this.specialSpecArr = arr;
            this.specialSpec = data;
        },
        assistant() {
            if (this.goodsUrl == "") {
                Message({
                    message: "请输入京东商品链接",
                    type: "error",
                    duration: 5 * 1000,
                });
                return;
            }
            let url = encodeURIComponent(this.goodsUrl);
            this.$router.push({
                name: "AddOrUpdateVisible",
                params: {id: url},
            });
        },
        // 添加销售规格
        addValues() {
            this.valueSize++;
            this.arr.push({attrId: this.valueSize, attrName: "", attrValue: ""});
        },
        // 数据回显
        async getGoodsInfo(id) {
            this.echoAfter = false;
            let {data} = await getGoodsInfoApi({id});
            this.goodsInfo = data;
            if (data.isSfLogistics === "" || data.isSfLogistics === null) {
                this.goodsInfo.isSfLogistics = false
            }
            if (data.isSfLogisticsPrice === "" || data.isSfLogisticsPrice === null) {
                this.goodsInfo.isSfLogisticsPrice = 0
            }
            if (data.isJdLogistics === "" || data.isJdLogistics === null) {
                this.goodsInfo.isJdLogistics = false
            }
            if (data.isJdLogisticsPrice === "" || data.isJdLogisticsPrice === null) {
                this.goodsInfo.isJdLogisticsPrice = 0
            }
            if (data.codeNum) {
                this.taxObj.codeNum = data.taxCode
                this.taxObj.smallTax = data.taxRate
                this.taxObj.serviceName = data.taxName
            }
            await this.initCategory(2, data.categoryId1)
            await this.initCategory(3, data.categoryId2)
            this.getFreightSelect();
            this.getInstallSelect();
            this.getDeliveryList();
            await this.getValueList(2);
            if (this.goodsInfo.saleAttrVo) {
                this.arr = this.goodsInfo.saleAttrVo;
            }
            if (data.jdUrl == "") {
                this.isJdUrl = "2";
            } else {
                this.isJdUrl = "1";
            }
            if (data.startNum == 1) {
                this.isStartNum = 1
            } else {
                this.isStartNum = 2
            }
            if (this.specialSpecArr.length > 0 && this.goodsInfo.specialSpec) {
                this.specialSpecArr.forEach((e, index) => {
                    let json;
                    if (data.specialSpec.indexOf("{") === 0) {
                        json = JSON.parse(data.specialSpec);
                    } else {
                        json = JSON.parse(`{${data.specialSpec}}`);
                    }
                    let key = e.key;
                    this.specialSpecArr[index].name = json[key];
                });
            }
            if (data.sjGoodsFile.length > 0) {
                data.sjGoodsFile.forEach((e, index) => {
                    this.imgList.push(e.url);
                });
            }
            this.echoAfter = true;
        },
        async getGoodsCodeInfo(id) {

            while (id.length % 4) {
                id += '=';
            }

            id = atob(id);


            id = id.replace("ZY", "")


            this.echoAfter = false;
            let {data} = await echoShareCodeApi({id});
            data.id = "";
            this.goodsInfo = data;
            if (data.isSfLogistics === "" || data.isSfLogistics === null) {
                this.goodsInfo.isSfLogistics = false
            }
            if (data.isSfLogisticsPrice === "" || data.isSfLogisticsPrice === null) {
                this.goodsInfo.isSfLogisticsPrice = 0
            }
            if (data.isJdLogistics === "" || data.isJdLogistics === null) {
                this.goodsInfo.isJdLogistics = false
            }
            if (data.isJdLogisticsPrice === "" || data.isJdLogisticsPrice === null) {
                this.goodsInfo.isJdLogisticsPrice = 0
            }
            if (data.codeNum) {
                this.taxObj.codeNum = data.taxCode
                this.taxObj.smallTax = data.taxRate
                this.taxObj.serviceName = data.taxName
            }
            await this.initCategory(2, data.categoryId1)
            await this.initCategory(3, data.categoryId2)
            this.getFreightSelect();
            this.getInstallSelect();
            this.getDeliveryList();
            await this.getValueList(2);
            if (this.goodsInfo.saleAttrVo) {
                this.arr = this.goodsInfo.saleAttrVo;
            }
            if (data.jdUrl == "") {
                this.isJdUrl = "2";
            } else {
                this.isJdUrl = "1";
            }
            if (data.startNum == 1) {
                this.isStartNum = 1
            } else {
                this.isStartNum = 2
            }
            if (this.specialSpecArr.length > 0 && this.goodsInfo.specialSpec) {
                this.specialSpecArr.forEach((e, index) => {
                    let json;
                    if (data.specialSpec.indexOf("{") === 0) {
                        json = JSON.parse(data.specialSpec);
                    } else {
                        json = JSON.parse(`{${data.specialSpec}}`);
                    }
                    let key = e.key;
                    this.specialSpecArr[index].name = json[key];
                });
            }
            if (data.sjGoodsFile.length > 0) {
                data.sjGoodsFile.forEach((e, index) => {
                    this.imgList.push(e.url);
                });
            }
            this.echoAfter = true;
        },
        //上架小助手
        async goodsAssistant(url) {
            url = decodeURIComponent(url);
            let id = url.match(/(\d+)/)[0];
            let {data} = await getGoodsAssistantApi({id});
            await this.getTaxList()
            data.spuId = '';
            if (!data.isTwoShow) {
                data.isTwoShow = 2;
            }
            this.goodsInfo = data;
            if (data.isJdLogistics === "" || data.isJdLogistics === null) {
                this.goodsInfo.isJdLogistics = false
            }
            if (data.isJdLogisticsPrice === "" || data.isJdLogisticsPrice === null) {
                this.goodsInfo.isJdLogisticsPrice = 0
            }
            if (data.isSfLogistics === "" || data.isSfLogistics === null) {
                this.goodsInfo.isSfLogistics = false
            }
            if (data.isSfLogisticsPrice === "" || data.isSfLogisticsPrice === null) {
                this.goodsInfo.isSfLogisticsPrice = 0
            }
            this.initCategory(2, data.categoryId1)
            await this.initCategory(3, data.categoryId2)
            this.goodsInfo.saleAttrs = [];
            this.getFreightSelect();
            this.getDeliveryList();
            this.goodsInfo.freightId = "";
            await this.getValueList(2);
            if (data.jdUrl == "") {
                this.isJdUrl = "2";
            } else {
                this.isJdUrl = "1";
                this.goodsInfo.jdUrl = url;
            }
            this.goodsInfo.deliveryTime = "24小时内发货";
            this.goodsInfo.isDelivery = "2";
            this.goodsInfo.isInstall = "2";
            this.goodsInfo.isSfLogistics = false;
            this.goodsInfo.isSfLogisticsPrice = 0;
            this.goodsInfo.isJdLogistics = false;
            this.goodsInfo.isJdLogisticsPrice = 0;
            if (this.specialSpecArr.length > 0 && this.goodsInfo.specialSpec) {
                this.specialSpecArr.forEach((e, index) => {
                    let json = JSON.parse(`${data.specialSpec}`);
                    let key = e.key;
                    this.specialSpecArr[index].name = json[key];
                });
            }
            if (data.sjGoodsFile.length > 0) {
                data.sjGoodsFile.forEach((e, index) => {
                    this.imgList.push(e.url);
                });
            }
            if (data.taxCode) {
                let taxCode = data.taxCode.replace(/(0+)$/g, "")
                console.log(taxCode)
                this.taxObj.codeNum = taxCode
                this.taxObj.serviceName = this.taxList.filter(item => item.codeNum === taxCode)[0].serviceAbbName
                this.taxObj.smallTax = data.taxRate
                this.selectTaxItem()
            }
        },
        // 获取登录权限
        getLoginRole() {
            this.role = localStorage.getItem('role')
        },
        switchInputShow(value) {
            // 在 @input 事件中手动同步数据
            if ((this.role.includes('firstGradeSupplier') || this.role.includes('specialFirstGrade'))) {
                this.goodsInfo.isShow = value;
            } else {
                this.goodsInfo.isTwoShow = value;
            }
        }
    },
    created() {
        this.getLoginRole();
        this.initCategory();
        this.getFreightSelect();
        if (this.$route.params.id) {
            let id = this.$route.params.id;
            if (!isNaN(id)) {
                let status = this.$route.params.status;
                this.goodsStatus = status;
                this.getGoodsInfo(id);
            } else if (id.indexOf("https") === 0 || id.indexOf("http") === 0) {
                this.goodsAssistant(id);
            } else {
                this.getGoodsCodeInfo(id)
            }
        } else {
            this.goodsInfo.isJdLogistics = false
            this.goodsInfo.isSfLogistics = false
            this.goodsInfo.isSfLogisticsPrice = 0
            this.goodsInfo.isJdLogisticsPrice = 0
        }
    },
    mounted() {
    },
    computed: {
        ...mapState("user", ["permissionList"])
    },
    components: {uploadFileApi, Tinymce},
};
