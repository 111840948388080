<template>
  <div class="card border-0 h-md-100" data-bs-theme="light"
       style="background: linear-gradient(112.14deg, #00D2FF 0%, #3A7BD5 100%)">
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::Row-->
      <div class="row align-items-center h-100">
        <div class="col-7" style="position: relative;">
          <div class="text-white pt-16;" style="position: absolute;top: 10px">
            <span class="fw-bold" style="font-size: 28px">{{ obj.webTitle }}SaaS系统欢迎你</span>
            <span class="fs-4 fw-semibold me-2 d-block lh-1 pb-2 opacity-75">打造生意的N种可能</span>
          </div>
          <span class="fw-semibold text-white mb-8 d-block" style="font-size: 20px">
<!--                    打造生意的N种可能-->
                </span>
          <div style="color: #fff;margin-bottom: 15px;margin-left: 5px;margin-top: 120px">常用工具</div>
          <div class="d-flex flex-column flex-sm-row d-grid gap-2">
            <a href="#" class="btn btn-success flex-shrink-0 me-lg-2" @click="$refs.Assistant.open()">上架小助手</a>
            <!--            暂未开放尽情期待-->
            <a href="#" class="btn btn-primary flex-shrink-0" style="background: rgba(255, 255, 255, 0.2)"
               data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" @click="no">一键生成产品方案</a>
          </div>
        </div>

        <div class="col-5 pt-10">
          <div class="bgi-no-repeat bgi-size-contain bgi-position-x-end h-225px"
               style="background-image:url('http://sjzcsaas.oss-cn-hangzhou.aliyuncs.com/sj/frontPage/5.svg')">
          </div>
        </div>
      </div>
    </div>
    <!--    上架小助手-->
    <ModalDialog ref="Assistant" title="上架小助手">
      <div class="row mb-2">
        <label class="col-lg-3 fw-bold text-muted" style="margin-top: 10px"
        >*京东链接/商品码:</label
        >
        <div class="col-lg-9">
          <input
              id="goodsUrl"
              name="goodsUrl"
              v-model="goodsUrl"
              class="form-control form-control-solid"
              placeholder="请输入商品链接/商品码"
          />
        </div>
        <!--end::Col-->
        <div class="separator separator-dashed my-2"></div>
      </div>
      <div class="row mb-2">
        <!--begin::Label-->
        <label class="fw-bold text-muted" style="margin-top: 10px"
               v-clipboard="'https://item.jd.com/100044924745.html'"
        >示例:https://item.jd.com/100044924745.html&nbsp;&nbsp;&nbsp;<span class="text-primary">点击复制</span></label
        >
        <!--end::Label-->
      </div>
      <!--begin::Input group-->
      <div class="row fv-row mb-7 fv-plugins-icon-container"></div>
      <div class="modal-footer">
        <button
            type="button"
            class="btn btn-light"
            @click="$refs.Assistant.close()"
        >
          关闭
        </button>
        <button type="button" class="btn btn-primary" @click="assistant">
          提交
        </button>
      </div>
    </ModalDialog>

  </div>
</template>
<script>

import {mapState} from "vuex";

export default {
  name: "Jumbotron",
  data() {
    return {
      goodsUrl: ''
    }
  },
  methods: {
    assistant() {
      if (this.goodsUrl == "") {
        this.$swal.fire({
          icon: "error",
          title: "请输入京东商品链接!",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });
        return;
      }
      let url = encodeURIComponent(this.goodsUrl);
      this.$router.push({
        name: "AddOrUpdateVisible",
        params: {id: url},
      });
    },
    no() {
      this.$router.push({ path: '/goods', query: { openExportTool: true }});
    }
  },
  computed: {
    ...mapState("web", ["obj"]),
  },
}
</script>
