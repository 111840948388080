<template>
  <div>
    <el-form
      ref="ruleForm"
      :model="form"
      :rules="rules"
      class="demo-ruleForm"
      label-width="200px"
    >
      <el-form-item label="卡包类型" prop="type">
        <el-select v-model="form.type" disabled placeholder="请选择卡包类型">
          <el-option label="微信" value="1"></el-option>
          <el-option label="支付宝" value="2"></el-option>
          <el-option label="银行卡" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="form.type == '1'" label="微信收款二维码" prop="content">
        <el-upload
          class="upload-demo"
          ref="upload"
          action="string"
          :auto-upload="true"
          :http-request="handleChange"
          :show-file-list="false"
        >
          <el-avatar
            shape="square"
            :size="100"
            fit="fill"
            :src="form.content"
          ></el-avatar>
          点击图片进行修改
        </el-upload>
      </el-form-item>
      <!--   支付宝   -->
      <el-form-item
        v-if="form.type == '2' || form.type == '3'"
        :required="true"
        label="收款账号（或银行卡号）"
        prop="content"
      >
        <el-input
          v-model="form.content"
          placeholder="收款账号（或银行卡号）"
        ></el-input>
      </el-form-item>

      <el-form-item v-if="form.type != ''" label="收款人姓名" prop="name">
        <el-input v-model="form.name" placeholder="请输入内容"></el-input>
      </el-form-item>

      <!--   银行卡   -->
      <el-form-item v-if="(form.type=='2' && !isPhoneNumber(form.content)) || form.type == '3'" label="开户行" prop="bankName">
        <el-input v-model="form.bankName" placeholder="请输入开户行"></el-input>
      </el-form-item>
      <!--   账户类型   -->
      <el-form-item v-if="form.type == '3'" label="账户类型" prop="isBank">
        <el-select v-model="form.isBank" placeholder="请选择账户类型">
          <el-option label="对公账户" value="1"></el-option>
          <el-option label="个人账户" value="2"></el-option>
        </el-select>
      </el-form-item>
      <slot name="button"></slot>
    </el-form>
  </div>
</template>

<script>
import { uploadFileApi } from "@/api/uploadFileApi";
import { addStaffCz, getRechargeRecordListApi } from "@/api/staffCzApi";
import { getCardApi, updateCardApi } from "@/api/cardApi";

export default {
  name: "RechargeCreate",
  data() {
    const validateLogo = (rule, value, callback) => {
      if (!this.form.cover) {
        callback(new Error("请上传图片"));
      } else {
        callback();
      }
    };
    return {
      id: "",
      form: {
        // 卡包类型
        type: "",
        // 收款人姓名
        name: "",
        // 微信收款二维码
        cover: "",
        // 收款账号
        content: "",
        // 开户行
        bankName: "",
        // 账户类型
        isBank: "",
      },
      fileList: [],
      rules: {
        region: [
          { required: true, message: "请选择卡包类型", trigger: "change" },
        ],
        name: [
          { required: true, message: "请输入收款人姓名", trigger: "blur" },
        ],
        bankName: [
          { required: true, message: "请输入开户行", trigger: "blur" },
        ],
        account: [
          {
            required: true,
            message: "请输入收款账号（或银行卡号）",
            trigger: "blur",
          },
        ],
        accountType: [
          { required: true, message: "请选择账户类型", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    isPhoneNumber(value){
      const phoneRegex = /^1[3-9]\d{9}$/; // 简单的手机号验证正则表达式
      return phoneRegex.test(value);
    },
    async handleChange(param){
      const data = new FormData();
      if (param) {
        data.append("file", param.file);
        data.append("moduleName", "finance/rechargeRecords");
        if (param.file.size >= this.fileSize * 1024 * 1024) {
          this.$message({
            message: "文件过大,最大" + this.fileSize + "MB",
            type: "warning",
          });
          return;
        }
      }
      let {data:res} = await uploadFileApi(data);
      this.form.content=res.fileUrl
      this.form.cover=res.filePath
      this.$message({
        message: "上传成功!",
        type: "success",
          });
    },
    changeFile(a, b, c) {
      this.fileList = b;
    },
    submit() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          let obj=JSON.parse(JSON.stringify(this.form))
          if (obj.type==1) {
            obj.content=this.form.cover
          }
          await updateCardApi(JSON.stringify(obj))
          this.form.content=""
          this.form.cover=""
          this.$emit("success-submit")
          this.$emit('getOrderTwoList')
          return valid;
        } else {
          return false;
        }
      });
    },
    clearData() {
    },
    async getCardById() {
      this.form={
          type: "",
          name: "",
          cover: "",
          content: "",
          bankName: "",
          isBank: "",
      }
      let { data } = await getCardApi({
        id: this.id,
      });
      this.form = data;
    },
  },

  created() {},
};
</script>

<style scoped>
</style>