<template>
  <div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="30%"
      append-to-body
    >
      <el-form :model="addressForm" :rules="rules" ref="addressForm" label-width="120px">
        <el-form-item label="收件人" prop="name" style="margin: 0 auto;width: 80%">
          <el-input v-model="addressForm.name"></el-input>
        </el-form-item>
        <div style="height:10px"></div>

        <el-form-item label="收件人手机号" prop="tel" style="margin: 0 auto;width: 80%">
          <el-input v-model="addressForm.tel"></el-input>
        </el-form-item>
        <div style="height:10px"></div>
        <el-form-item :label="!isMunicipality?'选择省':'选择直辖市'" prop="provinceId" style="margin: 0 auto;width: 80%">
          <el-select v-model="addressForm.provinceId" placeholder="请选择省" @change="changeProvinceId">
            <el-option v-for="(item,index) in provinceList" :key="index" :label="item.name" :value="item.kid"></el-option>
            <!-- 更多省份选项 -->
          </el-select>
        </el-form-item>
        <div style="height:10px"></div>
        <el-form-item :label="!isMunicipality?'选择市':'选择区/县'" prop="cityId" style="margin: 0 auto;width: 80%">
          <el-select v-model="addressForm.cityId" :placeholder="!isMunicipality?'请选择市':'请选择区/县'" @change="changeCityId">
            <el-option v-for="(item,index) in cityList" :key="index" :label="item.name" :value="item.kid"></el-option>
            <!-- 更多城市选项 -->
          </el-select>
        </el-form-item>
        <div style="height:10px"></div>
        <el-form-item :label="!isMunicipality?'选择区/县':'选择乡镇/街道'" prop="areaId" style="margin: 0 auto;width: 80%">
          <el-select v-model="addressForm.areaId" :placeholder="!isMunicipality?'请选择区/县':'请选择乡镇/街道'" @change="changeAreaId">
            <el-option v-for="(item,index) in areaList" :key="index" :label="item.name" :value="item.kid"></el-option>
            <!-- 更多县选项 -->
          </el-select>

        </el-form-item>
        <div style="height:10px"></div>
        <el-form-item v-if="!isMunicipality" label="选择乡镇/街道" prop="streetId" style="margin: 0 auto;width: 80%">
          <el-select v-model="addressForm.streetId" placeholder="请选择乡镇/街道" @change="changeStreetId">
            <el-option v-for="(item,index) in streetList" :key="index" :label="item.name" :value="item.kid"></el-option>
            <!-- 更多街道选项 -->
          </el-select>
        </el-form-item>
        <div style="height:10px"></div>
        <el-form-item label="详细地址" prop="addressDetail" style="margin: 0 auto;width: 80%">
          <el-input v-model="addressForm.addressDetail"></el-input>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitForm">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

  import {getJdRegionList} from "@/api/jdRegionApi";
  import {addSaasAddressApi, getDefaultAddressDetailApi, updateSaasAddressApi} from "@/api/addressApi";

  export default {

    data(){
      return {
        dialogVisible:false,
        dialogTitle:"新增商品寄回地址",
        addressForm: {
          name: "",
          tel: "",
          addressDetail: "",
          type: "1",
          provinceId: "",
          cityId: "",
          areaId: "",
          streetId: "",
          provinceName: "",
          cityName: "",
          areaName: "",
          streetName: "",
        },
        rules: {
          name: [{ required: true, message: '请输入收件人', trigger: 'blur' }],
          tel: [
              { required: true, message: '请输入收件人手机号', trigger: 'blur' },
            { pattern: /^1[3-9]\d{9}$/, message: '手机号码格式不正确', trigger: 'blur'}
          ],
          provinceId: [{ required: true, message: '请选择省', trigger: 'change' }],
          cityId: [{ required: true, message: '请选择市', trigger: 'change' }],
          areaId: [{ required: true, message: '请选择县', trigger: 'change' }],
          streetId: [{ required: true, message: '请选择街道', trigger: 'change' }],
          addressDetail: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
        },
        provinceList:[],
        cityList:[],
        areaList:[],
        streetList:[],
        isMunicipality:false,
      }
    },
    methods:{
      init(){
        this.getDefaultAddressDetail()
        this.getProvinceList()
        this.dialogVisible=true;
      },
      async getDefaultAddressDetail(){
        let {data} = await getDefaultAddressDetailApi();
        if(data){
          this.dialogTitle = "编辑商品寄回地址"
          this.addressForm = data
          let provinceId = this.addressForm.provinceId
          if(provinceId ==1 || provinceId ==2 || provinceId ==3 || provinceId ==4 ){
            this.isMunicipality = true
          }else{
            this.isMunicipality = false
          }
          if(this.addressForm.provinceId){
            this.cityList = await this.getJdRegion(this.addressForm.provinceId)
          }
          if(this.addressForm.cityId){
            this.areaList = await this.getJdRegion(this.addressForm.cityId)
          }
          if(this.addressForm.areaId){
            this.streetList = await this.getJdRegion(this.addressForm.areaId)
          }
        }else{
          this.dialogTitle = "新增商品寄回地址"
        }
      },

      async getJdRegion(pId){
        let {data}  = await getJdRegionList({pId})
        return data
      },

      async getProvinceList(){
        this.provinceList = await this.getJdRegion(0)
      },

      async changeProvinceId(e){

        if(e==1 || e==2|| e==3 || e==4){
          this.isMunicipality = true
        }else{
          this.isMunicipality = false
        }

        this.addressForm.provinceName = this.provinceList.find(item=>item.kid===e).name

        this.cityList = await this.getJdRegion(e)
        this.addressForm.cityId = ""
        this.addressForm.cityName = ""

        this.addressForm.areaId = ""
        this.addressForm.areaName = ""

        this.addressForm.streetId = ""
        this.addressForm.streetName = ""
        this.areaList  =[]
        this.streetList = []
      },
      async changeCityId(e){
        this.addressForm.cityName = this.cityList.find(item=>item.kid===e).name
        this.areaList = await this.getJdRegion(e)
        this.addressForm.areaId = ""
        this.addressForm.areaName = ""

        this.addressForm.streetId = ""
        this.addressForm.streetName = ""
        this.streetList = []
      },
      async changeAreaId(e){
        this.addressForm.areaName = this.areaList.find(item=>item.kid===e).name
        this.streetList = await this.getJdRegion(e)
        this.addressForm.streetId = ""
        this.addressForm.streetName = ""
      },
      async changeStreetId(e){
        this.addressForm.streetName = this.streetList.find(item=>item.kid===e).name
      },
      submitForm(){
        this.$refs.addressForm.validate((valid)=>{
          if(valid){
            if(this.dialogTitle=="新增商品寄回地址"){
              this.addAddress()
            }else{
              this.updateAddress()
            }

          }else {
            return false
          }
        })
      },

      async addAddress(){
        let res = await addSaasAddressApi(this.addressForm)
        if(res.code === 200){
          this.$message({
            message: "添加成功",
            type: "success"
          })
          this.dialogVisible = false
          this.$emit("getAddressInfo")
        }else{
          this.$message({
            message: res.msg,
            type: "error"
          })
        }
      },
      async updateAddress(){
        let res = await updateSaasAddressApi(this.addressForm)
        if(res.code === 200){
          this.$message({
            message: "编辑成功",
            type: "success"
          })
          this.dialogVisible = false
        }else{
          this.$message({
            message: res.msg,
            type: "error"
          })
        }
      }


    },
    created() {

    }
  }

</script>
<style scoped>
.dialog-footer {

  text-align: right;

}
</style>
